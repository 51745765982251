.minimize-1 {
	animation-name: minimize-1;
	-webkit-animation-name: minimize-1;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease -out;

    animation-iteration-count:infinite;

	visibility: visible !important;	
}

@keyframes minimize-1 {
    10% {
        color: #ffffffff;
    }
    40% {
        transform: scaleX(2) scaleY(2);
        color: #ffffffff;
    }
    75% {
        transform: scaleX(1) scaleY(1);
        color: #ffffff00;
    }
	100%{
		transform: translateY(150%) translateX(680%) scaleX(0.01) scaleY(0.01);
	}
}

@-webkit-keyframes minimize-1 {
    10% {
        color: #ffffffff;
    }
    40% {
        -webkit-transform: scaleX(2) scaleY(2);
        color: #ffffffff;
    }
    75% {
        -webkit-transform: scaleX(1) scaleY(1);
        color: #ffffff00;
    }
	100%{
		-webkit-transform: translateY(150%) translateX(680%) scaleX(0.01) scaleY(0.01);
	}
}

.minimize-3 {
	animation-name: minimize-3;
	-webkit-animation-name: minimize-3;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease -out;

    animation-iteration-count:infinite;

	visibility: visible !important;	
}

@keyframes minimize-3 {
    10% {
        color: #ffffffff;
    }
    40% {
        transform: scaleX(2) scaleY(2);
        color: #ffffffff;
    }
    75% {
        transform: scaleX(1) scaleY(1);
        color: #ffffff00;
    }
	100%{
		transform: translateY(500%) translateX(-20%) scaleX(0.01) scaleY(0.01);
	}
}

@-webkit-keyframes minimize-3 {
    10% {
        color: #ffffffff;
    }
    40% {
        -webkit-transform: scaleX(2) scaleY(2);
        color: #ffffffff;
    }
    75% {
        -webkit-transform: scaleX(1) scaleY(1);
        color: #ffffff00;
    }
	100%{
		-webkit-transform: translateY(500%) translateX(-20%) scaleX(0.01) scaleY(0.01);
	}
}

.minimize-4 {
	animation-name: minimize-4;
	-webkit-animation-name: minimize-4;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease -out;

    animation-iteration-count:infinite;

	visibility: visible !important;	
}

@keyframes minimize-4 {
    10% {
        color: #ffffffff;
    }
    40% {
        transform: scaleX(2) scaleY(2);
        color: #ffffffff;
    }
    75% {
        transform: scaleX(1) scaleY(1);
        color: #ffffff00;
    }
	100%{
		transform: translateY(800%) translateX(-250%) scaleX(0.01) scaleY(0.01);
	}
}

@-webkit-keyframes minimize-4 {
    10% {
        color: #ffffffff;
    }
    40% {
        -webkit-transform: scaleX(2) scaleY(2);
        color: #ffffffff;
    }
    75% {
        -webkit-transform: scaleX(1) scaleY(1);
        color: #ffffff00;
    }
	100%{
		-webkit-transform: translateY(800%) translateX(-250%) scaleX(0.01) scaleY(0.01);
	}
}


.shape-triangle {
    // Original shape
    width: 0;
    height: 0;
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-bottom: 2.1rem solid var(--bg, $primary);
    // Animation
    //color: var(--bg, $primary);
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 2.7rem;
    color: #ffffff00;
    text-indent: -0.6rem;
    text-align: center;
    // Position
    position: absolute;
    top: var(--top);
    left: var(--left);
}

.shape-circle {
    width: 2.2rem;
    height: 2.2rem;
    background: $primary;
    border-radius: 50%;
    // Animation
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 2.3rem;
    color: #ffffff00;
    text-align: center;
    // Position
    position: absolute;
    top: var(--top);
    left: var(--left);
}

.shape-square {
    width: 2.2rem;
    height: 2.2rem;
    background: $primary;
    // Animation
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 2.3rem;
    color: #ffffff00;
    text-align: center;
    // Position
    position: absolute;
    top: var(--top);
    left: var(--left);
}

.shape-diamond {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: $primary;
    position: relative;
    top: -50px;

    &::after {
        content: '';
        position: absolute;
        left: -50px;
        top: 50px;
        width: 0;
        height: 0;
        border: 50px solid transparent;
        border-top-color: $primary;
    }
}

.landing-img {
    position:relative;
}

.img-under {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
}

.img-over {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
}
