@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: "\ebcc";
}
.icon-airplay:before {
  content: "\ebcd";
}
.icon-alert-circle:before {
  content: "\ebce";
}
.icon-alert-octagon:before {
  content: "\ebcf";
}
.icon-alert-triangle:before {
  content: "\ebd0";
}
.icon-align-center2:before {
  content: "\ebd1";
}
.icon-align-justify2:before {
  content: "\ebd2";
}
.icon-align-left2:before {
  content: "\ebd3";
}
.icon-align-right2:before {
  content: "\ebd4";
}
.icon-anchor2:before {
  content: "\ebd5";
}
.icon-aperture:before {
  content: "\ebd6";
}
.icon-archive2:before {
  content: "\ebd7";
}
.icon-arrow-down2:before {
  content: "\ebd8";
}
.icon-arrow-down-circle:before {
  content: "\ebd9";
}
.icon-arrow-down-left:before {
  content: "\ebda";
}
.icon-arrow-down-right:before {
  content: "\ebdb";
}
.icon-arrow-left2:before {
  content: "\ebdc";
}
.icon-arrow-left-circle:before {
  content: "\ebdd";
}
.icon-arrow-right2:before {
  content: "\ebde";
}
.icon-arrow-right-circle:before {
  content: "\ebdf";
}
.icon-arrow-up2:before {
  content: "\ebe0";
}
.icon-arrow-up-circle:before {
  content: "\ebe1";
}
.icon-arrow-up-left:before {
  content: "\ebe2";
}
.icon-arrow-up-right:before {
  content: "\ebe3";
}
.icon-at-sign:before {
  content: "\ebe4";
}
.icon-award:before {
  content: "\ebe5";
}
.icon-bar-chart2:before {
  content: "\ebe6";
}
.icon-bar-chart-2:before {
  content: "\ebe7";
}
.icon-battery2:before {
  content: "\ebe8";
}
.icon-battery-charging:before {
  content: "\ebe9";
}
.icon-bell2:before {
  content: "\ebea";
}
.icon-bell-off:before {
  content: "\ebeb";
}
.icon-bluetooth2:before {
  content: "\ebec";
}
.icon-bold2:before {
  content: "\ebed";
}
.icon-book2:before {
  content: "\ebee";
}
.icon-book-open:before {
  content: "\ebef";
}
.icon-bookmark2:before {
  content: "\ebf0";
}
.icon-box:before {
  content: "\ebf1";
}
.icon-briefcase2:before {
  content: "\ebf2";
}
.icon-calendar2:before {
  content: "\ebf3";
}
.icon-camera2:before {
  content: "\ebf4";
}
.icon-camera-off:before {
  content: "\ebf5";
}
.icon-cast:before {
  content: "\ebf6";
}
.icon-check2:before {
  content: "\ebf7";
}
.icon-check-circle2:before {
  content: "\ebf8";
}
.icon-check-square2:before {
  content: "\ebf9";
}
.icon-chevron-down2:before {
  content: "\ebfa";
}
.icon-chevron-left2:before {
  content: "\ebfb";
}
.icon-chevron-right2:before {
  content: "\ebfc";
}
.icon-chevron-up2:before {
  content: "\ebfd";
}
.icon-chevrons-down:before {
  content: "\ebfe";
}
.icon-chevrons-left:before {
  content: "\ebff";
}
.icon-chevrons-right:before {
  content: "\ec00";
}
.icon-chevrons-up:before {
  content: "\ec01";
}
.icon-chrome2:before {
  content: "\ec02";
}
.icon-circle2:before {
  content: "\ec03";
}
.icon-clipboard2:before {
  content: "\ec04";
}
.icon-clock:before {
  content: "\ec05";
}
.icon-cloud2:before {
  content: "\ec06";
}
.icon-cloud-drizzle:before {
  content: "\ec07";
}
.icon-cloud-lightning:before {
  content: "\ec08";
}
.icon-cloud-off:before {
  content: "\ec09";
}
.icon-cloud-rain:before {
  content: "\ec0a";
}
.icon-cloud-snow:before {
  content: "\ec0b";
}
.icon-code2:before {
  content: "\ec0c";
}
.icon-codepen2:before {
  content: "\ec0d";
}
.icon-command:before {
  content: "\ec0e";
}
.icon-compass2:before {
  content: "\ec0f";
}
.icon-copy2:before {
  content: "\ec10";
}
.icon-corner-down-left:before {
  content: "\ec11";
}
.icon-corner-down-right:before {
  content: "\ec12";
}
.icon-corner-left-down:before {
  content: "\ec13";
}
.icon-corner-left-up:before {
  content: "\ec14";
}
.icon-corner-right-down:before {
  content: "\ec15";
}
.icon-corner-right-up:before {
  content: "\ec16";
}
.icon-corner-up-left:before {
  content: "\ec17";
}
.icon-corner-up-right:before {
  content: "\ec18";
}
.icon-cpu:before {
  content: "\ec19";
}
.icon-credit-card2:before {
  content: "\ec1a";
}
.icon-crop2:before {
  content: "\ec1b";
}
.icon-crosshair:before {
  content: "\ec1c";
}
.icon-database2:before {
  content: "\ec1d";
}
.icon-delete:before {
  content: "\ec1e";
}
.icon-disc:before {
  content: "\ec1f";
}
.icon-dollar-sign:before {
  content: "\ec20";
}
.icon-download2:before {
  content: "\ec21";
}
.icon-download-cloud:before {
  content: "\ec22";
}
.icon-droplet:before {
  content: "\ec23";
}
.icon-edit2:before {
  content: "\ec24";
}
.icon-edit-2:before {
  content: "\ec25";
}
.icon-edit-3:before {
  content: "\ec26";
}
.icon-external-link2:before {
  content: "\ec27";
}
.icon-eye2:before {
  content: "\ec28";
}
.icon-eye-off:before {
  content: "\ec29";
}
.icon-facebook2:before {
  content: "\ec2a";
}
.icon-fast-forward2:before {
  content: "\ec2b";
}
.icon-feather:before {
  content: "\ec2c";
}
.icon-file2:before {
  content: "\ec2d";
}
.icon-file-minus:before {
  content: "\ec2e";
}
.icon-file-plus:before {
  content: "\ec2f";
}
.icon-file-text2:before {
  content: "\ec30";
}
.icon-film2:before {
  content: "\ec31";
}
.icon-filter2:before {
  content: "\ec32";
}
.icon-flag2:before {
  content: "\ec33";
}
.icon-folder2:before {
  content: "\ec34";
}
.icon-folder-minus:before {
  content: "\ec35";
}
.icon-folder-plus:before {
  content: "\ec36";
}
.icon-gift2:before {
  content: "\ec37";
}
.icon-git-branch:before {
  content: "\ec38";
}
.icon-git-commit:before {
  content: "\ec39";
}
.icon-git-merge:before {
  content: "\ec3a";
}
.icon-git-pull-request:before {
  content: "\ec3b";
}
.icon-github2:before {
  content: "\ec3c";
}
.icon-gitlab2:before {
  content: "\ec3d";
}
.icon-globe2:before {
  content: "\ec3e";
}
.icon-grid:before {
  content: "\ec3f";
}
.icon-hard-drive:before {
  content: "\ec40";
}
.icon-hash:before {
  content: "\ec41";
}
.icon-headphones2:before {
  content: "\ec42";
}
.icon-heart2:before {
  content: "\ec43";
}
.icon-help-circle:before {
  content: "\ec44";
}
.icon-home2:before {
  content: "\ec45";
}
.icon-image2:before {
  content: "\ec46";
}
.icon-inbox2:before {
  content: "\ec47";
}
.icon-info2:before {
  content: "\ec48";
}
.icon-instagram2:before {
  content: "\ec49";
}
.icon-italic2:before {
  content: "\ec4a";
}
.icon-layers:before {
  content: "\ec4b";
}
.icon-layout:before {
  content: "\ec4c";
}
.icon-life-buoy2:before {
  content: "\ec4d";
}
.icon-link2:before {
  content: "\ec4e";
}
.icon-link-2:before {
  content: "\ec4f";
}
.icon-linkedin2:before {
  content: "\ec50";
}
.icon-list2:before {
  content: "\ec51";
}
.icon-loader:before {
  content: "\ec52";
}
.icon-lock2:before {
  content: "\ec53";
}
.icon-log-in:before {
  content: "\ec54";
}
.icon-log-out:before {
  content: "\ec55";
}
.icon-mail:before {
  content: "\ec56";
}
.icon-map2:before {
  content: "\ec57";
}
.icon-map-pin2:before {
  content: "\ec58";
}
.icon-maximize:before {
  content: "\ec59";
}
.icon-maximize-2:before {
  content: "\ec5a";
}
.icon-menu:before {
  content: "\ec5b";
}
.icon-message-circle:before {
  content: "\ec5c";
}
.icon-message-square:before {
  content: "\ec5d";
}
.icon-mic:before {
  content: "\ec5e";
}
.icon-mic-off:before {
  content: "\ec5f";
}
.icon-minimize:before {
  content: "\ec60";
}
.icon-minimize-2:before {
  content: "\ec61";
}
.icon-minus2:before {
  content: "\ec62";
}
.icon-minus-circle2:before {
  content: "\ec63";
}
.icon-minus-square2:before {
  content: "\ec64";
}
.icon-monitor:before {
  content: "\ec65";
}
.icon-moon:before {
  content: "\ec66";
}
.icon-more-horizontal:before {
  content: "\ec67";
}
.icon-more-vertical:before {
  content: "\ec68";
}
.icon-move:before {
  content: "\ec69";
}
.icon-music2:before {
  content: "\ec6a";
}
.icon-navigation:before {
  content: "\ec6b";
}
.icon-navigation-2:before {
  content: "\ec6c";
}
.icon-octagon:before {
  content: "\ec6d";
}
.icon-package:before {
  content: "\ec6e";
}
.icon-paperclip2:before {
  content: "\ec6f";
}
.icon-pause2:before {
  content: "\ec70";
}
.icon-pause-circle2:before {
  content: "\ec71";
}
.icon-percent2:before {
  content: "\ec72";
}
.icon-phone2:before {
  content: "\ec73";
}
.icon-phone-call:before {
  content: "\ec74";
}
.icon-phone-forwarded:before {
  content: "\ec75";
}
.icon-phone-incoming:before {
  content: "\ec76";
}
.icon-phone-missed:before {
  content: "\ec77";
}
.icon-phone-off:before {
  content: "\ec78";
}
.icon-phone-outgoing:before {
  content: "\ec79";
}
.icon-pie-chart2:before {
  content: "\ec7a";
}
.icon-play2:before {
  content: "\ec7b";
}
.icon-play-circle2:before {
  content: "\ec7c";
}
.icon-plus2:before {
  content: "\ec7d";
}
.icon-plus-circle2:before {
  content: "\ec7e";
}
.icon-plus-square2:before {
  content: "\ec7f";
}
.icon-pocket:before {
  content: "\ec80";
}
.icon-power:before {
  content: "\ec81";
}
.icon-printer:before {
  content: "\ec82";
}
.icon-radio:before {
  content: "\ec83";
}
.icon-refresh-ccw:before {
  content: "\ec84";
}
.icon-refresh-cw:before {
  content: "\ec85";
}
.icon-repeat2:before {
  content: "\ec86";
}
.icon-rewind:before {
  content: "\ec87";
}
.icon-rotate-ccw:before {
  content: "\ec88";
}
.icon-rotate-cw:before {
  content: "\ec89";
}
.icon-rss2:before {
  content: "\ec8a";
}
.icon-save2:before {
  content: "\ec8b";
}
.icon-scissors2:before {
  content: "\ec8c";
}
.icon-search2:before {
  content: "\ec8d";
}
.icon-send2:before {
  content: "\ec8e";
}
.icon-server2:before {
  content: "\ec8f";
}
.icon-settings:before {
  content: "\ec90";
}
.icon-share2:before {
  content: "\ec91";
}
.icon-share-2:before {
  content: "\ec92";
}
.icon-shield2:before {
  content: "\ec93";
}
.icon-shield-off:before {
  content: "\ec94";
}
.icon-shopping-bag2:before {
  content: "\ec95";
}
.icon-shopping-cart2:before {
  content: "\ec96";
}
.icon-shuffle:before {
  content: "\ec97";
}
.icon-sidebar:before {
  content: "\ec98";
}
.icon-skip-back:before {
  content: "\ec99";
}
.icon-skip-forward:before {
  content: "\ec9a";
}
.icon-slack2:before {
  content: "\ec9b";
}
.icon-slash:before {
  content: "\ec9c";
}
.icon-sliders2:before {
  content: "\ec9d";
}
.icon-smartphone:before {
  content: "\ec9e";
}
.icon-speaker:before {
  content: "\ec9f";
}
.icon-square2:before {
  content: "\eca0";
}
.icon-star2:before {
  content: "\eca1";
}
.icon-stop-circle2:before {
  content: "\eca2";
}
.icon-sun:before {
  content: "\eca3";
}
.icon-sunrise:before {
  content: "\eca4";
}
.icon-sunset:before {
  content: "\eca5";
}
.icon-tablet2:before {
  content: "\eca6";
}
.icon-tag2:before {
  content: "\eca7";
}
.icon-target:before {
  content: "\eca8";
}
.icon-terminal2:before {
  content: "\eca9";
}
.icon-thermometer2:before {
  content: "\ecaa";
}
.icon-thumbs-down2:before {
  content: "\ecab";
}
.icon-thumbs-up2:before {
  content: "\ecac";
}
.icon-toggle-left2:before {
  content: "\ecad";
}
.icon-toggle-right2:before {
  content: "\ecae";
}
.icon-trash2:before {
  content: "\ecaf";
}
.icon-trash-2:before {
  content: "\ecb0";
}
.icon-trending-down:before {
  content: "\ecb1";
}
.icon-trending-up:before {
  content: "\ecb2";
}
.icon-triangle:before {
  content: "\ecb3";
}
.icon-truck2:before {
  content: "\ecb4";
}
.icon-tv2:before {
  content: "\ecb5";
}
.icon-twitter2:before {
  content: "\ecb6";
}
.icon-type:before {
  content: "\ecb7";
}
.icon-umbrella2:before {
  content: "\ecb8";
}
.icon-underline2:before {
  content: "\ecb9";
}
.icon-unlock2:before {
  content: "\ecba";
}
.icon-upload2:before {
  content: "\ecbb";
}
.icon-upload-cloud:before {
  content: "\ecbc";
}
.icon-user2:before {
  content: "\ecbd";
}
.icon-user-check:before {
  content: "\ecbe";
}
.icon-user-minus:before {
  content: "\ecbf";
}
.icon-user-plus2:before {
  content: "\ecc0";
}
.icon-user-x:before {
  content: "\ecc1";
}
.icon-users2:before {
  content: "\ecc2";
}
.icon-video:before {
  content: "\ecc3";
}
.icon-video-off:before {
  content: "\ecc4";
}
.icon-voicemail:before {
  content: "\ecc5";
}
.icon-volume:before {
  content: "\ecc6";
}
.icon-volume-1:before {
  content: "\ecc7";
}
.icon-volume-2:before {
  content: "\ecc8";
}
.icon-volume-x:before {
  content: "\ecc9";
}
.icon-watch:before {
  content: "\ecca";
}
.icon-wifi2:before {
  content: "\eccb";
}
.icon-wifi-off:before {
  content: "\eccc";
}
.icon-wind:before {
  content: "\eccd";
}
.icon-x:before {
  content: "\ecce";
}
.icon-x-circle:before {
  content: "\eccf";
}
.icon-x-square:before {
  content: "\ecd0";
}
.icon-youtube2:before {
  content: "\ecd1";
}
.icon-zap:before {
  content: "\ecd2";
}
.icon-zap-off:before {
  content: "\ecd3";
}
.icon-zoom-in:before {
  content: "\ecd4";
}
.icon-zoom-out:before {
  content: "\ecd5";
}
.icon-asterisk:before {
  content: "\f069";
}
.icon-plus:before {
  content: "\f067";
}
.icon-question:before {
  content: "\f128";
}
.icon-minus:before {
  content: "\f068";
}
.icon-glass:before {
  content: "\f000";
}
.icon-music:before {
  content: "\f001";
}
.icon-search:before {
  content: "\f002";
}
.icon-envelope-o:before {
  content: "\f003";
}
.icon-heart:before {
  content: "\f004";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-user:before {
  content: "\f007";
}
.icon-film:before {
  content: "\f008";
}
.icon-th-large:before {
  content: "\f009";
}
.icon-th:before {
  content: "\f00a";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-power-off:before {
  content: "\f011";
}
.icon-signal:before {
  content: "\f012";
}
.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-trash-o:before {
  content: "\f014";
}
.icon-home:before {
  content: "\f015";
}
.icon-file-o:before {
  content: "\f016";
}
.icon-clock-o:before {
  content: "\f017";
}
.icon-road:before {
  content: "\f018";
}
.icon-download:before {
  content: "\f019";
}
.icon-arrow-circle-o-down:before {
  content: "\f01a";
}
.icon-arrow-circle-o-up:before {
  content: "\f01b";
}
.icon-inbox:before {
  content: "\f01c";
}
.icon-play-circle-o:before {
  content: "\f01d";
}
.icon-repeat:before {
  content: "\f01e";
}
.icon-rotate-right:before {
  content: "\f01e";
}
.icon-refresh:before {
  content: "\f021";
}
.icon-list-alt:before {
  content: "\f022";
}
.icon-lock:before {
  content: "\f023";
}
.icon-flag:before {
  content: "\f024";
}
.icon-headphones:before {
  content: "\f025";
}
.icon-volume-off:before {
  content: "\f026";
}
.icon-volume-down:before {
  content: "\f027";
}
.icon-volume-up:before {
  content: "\f028";
}
.icon-qrcode:before {
  content: "\f029";
}
.icon-barcode:before {
  content: "\f02a";
}
.icon-tag:before {
  content: "\f02b";
}
.icon-tags:before {
  content: "\f02c";
}
.icon-book:before {
  content: "\f02d";
}
.icon-bookmark:before {
  content: "\f02e";
}
.icon-print:before {
  content: "\f02f";
}
.icon-camera:before {
  content: "\f030";
}
.icon-font:before {
  content: "\f031";
}
.icon-bold:before {
  content: "\f032";
}
.icon-italic:before {
  content: "\f033";
}
.icon-text-height:before {
  content: "\f034";
}
.icon-text-width:before {
  content: "\f035";
}
.icon-align-left:before {
  content: "\f036";
}
.icon-align-center:before {
  content: "\f037";
}
.icon-align-right:before {
  content: "\f038";
}
.icon-align-justify:before {
  content: "\f039";
}
.icon-list:before {
  content: "\f03a";
}
.icon-dedent:before {
  content: "\f03b";
}
.icon-outdent:before {
  content: "\f03b";
}
.icon-indent:before {
  content: "\f03c";
}
.icon-video-camera:before {
  content: "\f03d";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-adjust:before {
  content: "\f042";
}
.icon-tint:before {
  content: "\f043";
}
.icon-edit:before {
  content: "\f044";
}
.icon-pencil-square-o:before {
  content: "\f044";
}
.icon-share-square-o:before {
  content: "\f045";
}
.icon-check-square-o:before {
  content: "\f046";
}
.icon-arrows:before {
  content: "\f047";
}
.icon-step-backward:before {
  content: "\f048";
}
.icon-fast-backward:before {
  content: "\f049";
}
.icon-backward:before {
  content: "\f04a";
}
.icon-play:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-stop:before {
  content: "\f04d";
}
.icon-forward:before {
  content: "\f04e";
}
.icon-fast-forward:before {
  content: "\f050";
}
.icon-step-forward:before {
  content: "\f051";
}
.icon-eject:before {
  content: "\f052";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-plus-circle:before {
  content: "\f055";
}
.icon-minus-circle:before {
  content: "\f056";
}
.icon-times-circle:before {
  content: "\f057";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-info-circle:before {
  content: "\f05a";
}
.icon-crosshairs:before {
  content: "\f05b";
}
.icon-times-circle-o:before {
  content: "\f05c";
}
.icon-check-circle-o:before {
  content: "\f05d";
}
.icon-ban:before {
  content: "\f05e";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-mail-forward:before {
  content: "\f064";
}
.icon-share:before {
  content: "\f064";
}
.icon-expand:before {
  content: "\f065";
}
.icon-compress:before {
  content: "\f066";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-gift:before {
  content: "\f06b";
}
.icon-leaf:before {
  content: "\f06c";
}
.icon-fire:before {
  content: "\f06d";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-eye-slash:before {
  content: "\f070";
}
.icon-exclamation-triangle:before {
  content: "\f071";
}
.icon-warning:before {
  content: "\f071";
}
.icon-plane:before {
  content: "\f072";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-random:before {
  content: "\f074";
}
.icon-comment:before {
  content: "\f075";
}
.icon-magnet:before {
  content: "\f076";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-retweet:before {
  content: "\f079";
}
.icon-shopping-cart:before {
  content: "\f07a";
}
.icon-folder:before {
  content: "\f07b";
}
.icon-folder-open:before {
  content: "\f07c";
}
.icon-arrows-v:before {
  content: "\f07d";
}
.icon-arrows-h:before {
  content: "\f07e";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-twitter-square:before {
  content: "\f081";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-camera-retro:before {
  content: "\f083";
}
.icon-key:before {
  content: "\f084";
}
.icon-cogs:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-comments:before {
  content: "\f086";
}
.icon-thumbs-o-up:before {
  content: "\f087";
}
.icon-thumbs-o-down:before {
  content: "\f088";
}
.icon-star-half:before {
  content: "\f089";
}
.icon-heart-o:before {
  content: "\f08a";
}
.icon-sign-out:before {
  content: "\f08b";
}
.icon-linkedin-square:before {
  content: "\f08c";
}
.icon-thumb-tack:before {
  content: "\f08d";
}
.icon-external-link:before {
  content: "\f08e";
}
.icon-sign-in:before {
  content: "\f090";
}
.icon-trophy:before {
  content: "\f091";
}
.icon-github-square:before {
  content: "\f092";
}
.icon-upload:before {
  content: "\f093";
}
.icon-lemon-o:before {
  content: "\f094";
}
.icon-phone:before {
  content: "\f095";
}
.icon-square-o:before {
  content: "\f096";
}
.icon-bookmark-o:before {
  content: "\f097";
}
.icon-phone-square:before {
  content: "\f098";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-facebook-f:before {
  content: "\f09a";
}
.icon-github:before {
  content: "\f09b";
}
.icon-unlock:before {
  content: "\f09c";
}
.icon-credit-card:before {
  content: "\f09d";
}
.icon-feed:before {
  content: "\f09e";
}
.icon-rss:before {
  content: "\f09e";
}
.icon-hdd-o:before {
  content: "\f0a0";
}
.icon-bullhorn:before {
  content: "\f0a1";
}
.icon-bell-o:before {
  content: "\f0a2";
}
.icon-certificate:before {
  content: "\f0a3";
}
.icon-hand-o-right:before {
  content: "\f0a4";
}
.icon-hand-o-left:before {
  content: "\f0a5";
}
.icon-hand-o-up:before {
  content: "\f0a6";
}
.icon-hand-o-down:before {
  content: "\f0a7";
}
.icon-arrow-circle-left:before {
  content: "\f0a8";
}
.icon-arrow-circle-right:before {
  content: "\f0a9";
}
.icon-arrow-circle-up:before {
  content: "\f0aa";
}
.icon-arrow-circle-down:before {
  content: "\f0ab";
}
.icon-globe:before {
  content: "\f0ac";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-tasks:before {
  content: "\f0ae";
}
.icon-filter:before {
  content: "\f0b0";
}
.icon-briefcase:before {
  content: "\f0b1";
}
.icon-arrows-alt:before {
  content: "\f0b2";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link:before {
  content: "\f0c1";
}
.icon-cloud:before {
  content: "\f0c2";
}
.icon-flask:before {
  content: "\f0c3";
}
.icon-cut:before {
  content: "\f0c4";
}
.icon-scissors:before {
  content: "\f0c4";
}
.icon-copy:before {
  content: "\f0c5";
}
.icon-files-o:before {
  content: "\f0c5";
}
.icon-paperclip:before {
  content: "\f0c6";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-square:before {
  content: "\f0c8";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-list-ul:before {
  content: "\f0ca";
}
.icon-list-ol:before {
  content: "\f0cb";
}
.icon-strikethrough:before {
  content: "\f0cc";
}
.icon-underline:before {
  content: "\f0cd";
}
.icon-table:before {
  content: "\f0ce";
}
.icon-magic:before {
  content: "\f0d0";
}
.icon-truck:before {
  content: "\f0d1";
}
.icon-pinterest:before {
  content: "\f0d2";
}
.icon-pinterest-square:before {
  content: "\f0d3";
}
.icon-google-plus-square:before {
  content: "\f0d4";
}
.icon-google-plus:before {
  content: "\f0d5";
}
.icon-money:before {
  content: "\f0d6";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-columns:before {
  content: "\f0db";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-unsorted:before {
  content: "\f0dc";
}
.icon-sort-desc:before {
  content: "\f0dd";
}
.icon-sort-down:before {
  content: "\f0dd";
}
.icon-sort-asc:before {
  content: "\f0de";
}
.icon-sort-up:before {
  content: "\f0de";
}
.icon-envelope:before {
  content: "\f0e0";
}
.icon-linkedin:before {
  content: "\f0e1";
}
.icon-rotate-left:before {
  content: "\f0e2";
}
.icon-undo:before {
  content: "\f0e2";
}
.icon-gavel:before {
  content: "\f0e3";
}
.icon-legal:before {
  content: "\f0e3";
}
.icon-dashboard:before {
  content: "\f0e4";
}
.icon-tachometer:before {
  content: "\f0e4";
}
.icon-comment-o:before {
  content: "\f0e5";
}
.icon-comments-o:before {
  content: "\f0e6";
}
.icon-bolt:before {
  content: "\f0e7";
}
.icon-flash:before {
  content: "\f0e7";
}
.icon-sitemap:before {
  content: "\f0e8";
}
.icon-umbrella:before {
  content: "\f0e9";
}
.icon-clipboard:before {
  content: "\f0ea";
}
.icon-paste:before {
  content: "\f0ea";
}
.icon-lightbulb-o:before {
  content: "\f0eb";
}
.icon-exchange:before {
  content: "\f0ec";
}
.icon-cloud-download:before {
  content: "\f0ed";
}
.icon-cloud-upload:before {
  content: "\f0ee";
}
.icon-user-md:before {
  content: "\f0f0";
}
.icon-stethoscope:before {
  content: "\f0f1";
}
.icon-suitcase:before {
  content: "\f0f2";
}
.icon-bell:before {
  content: "\f0f3";
}
.icon-coffee:before {
  content: "\f0f4";
}
.icon-cutlery:before {
  content: "\f0f5";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-building-o:before {
  content: "\f0f7";
}
.icon-hospital-o:before {
  content: "\f0f8";
}
.icon-ambulance:before {
  content: "\f0f9";
}
.icon-medkit:before {
  content: "\f0fa";
}
.icon-fighter-jet:before {
  content: "\f0fb";
}
.icon-beer:before {
  content: "\f0fc";
}
.icon-h-square:before {
  content: "\f0fd";
}
.icon-plus-square:before {
  content: "\f0fe";
}
.icon-angle-double-left:before {
  content: "\f100";
}
.icon-angle-double-right:before {
  content: "\f101";
}
.icon-angle-double-up:before {
  content: "\f102";
}
.icon-angle-double-down:before {
  content: "\f103";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-desktop:before {
  content: "\f108";
}
.icon-laptop:before {
  content: "\f109";
}
.icon-tablet:before {
  content: "\f10a";
}
.icon-mobile:before {
  content: "\f10b";
}
.icon-mobile-phone:before {
  content: "\f10b";
}
.icon-circle-o:before {
  content: "\f10c";
}
.icon-quote-left:before {
  content: "\f10d";
}
.icon-quote-right:before {
  content: "\f10e";
}
.icon-spinner:before {
  content: "\f110";
}
.icon-circle:before {
  content: "\f111";
}
.icon-mail-reply:before {
  content: "\f112";
}
.icon-reply:before {
  content: "\f112";
}
.icon-github-alt:before {
  content: "\f113";
}
.icon-folder-o:before {
  content: "\f114";
}
.icon-folder-open-o:before {
  content: "\f115";
}
.icon-smile-o:before {
  content: "\f118";
}
.icon-frown-o:before {
  content: "\f119";
}
.icon-meh-o:before {
  content: "\f11a";
}
.icon-gamepad:before {
  content: "\f11b";
}
.icon-keyboard-o:before {
  content: "\f11c";
}
.icon-flag-o:before {
  content: "\f11d";
}
.icon-flag-checkered:before {
  content: "\f11e";
}
.icon-terminal:before {
  content: "\f120";
}
.icon-code:before {
  content: "\f121";
}
.icon-mail-reply-all:before {
  content: "\f122";
}
.icon-reply-all:before {
  content: "\f122";
}
.icon-star-half-empty:before {
  content: "\f123";
}
.icon-star-half-full:before {
  content: "\f123";
}
.icon-star-half-o:before {
  content: "\f123";
}
.icon-location-arrow:before {
  content: "\f124";
}
.icon-crop:before {
  content: "\f125";
}
.icon-code-fork:before {
  content: "\f126";
}
.icon-chain-broken:before {
  content: "\f127";
}
.icon-unlink:before {
  content: "\f127";
}
.icon-info:before {
  content: "\f129";
}
.icon-exclamation:before {
  content: "\f12a";
}
.icon-superscript:before {
  content: "\f12b";
}
.icon-subscript:before {
  content: "\f12c";
}
.icon-eraser:before {
  content: "\f12d";
}
.icon-puzzle-piece:before {
  content: "\f12e";
}
.icon-microphone:before {
  content: "\f130";
}
.icon-microphone-slash:before {
  content: "\f131";
}
.icon-shield:before {
  content: "\f132";
}
.icon-calendar-o:before {
  content: "\f133";
}
.icon-fire-extinguisher:before {
  content: "\f134";
}
.icon-rocket:before {
  content: "\f135";
}
.icon-maxcdn:before {
  content: "\f136";
}
.icon-chevron-circle-left:before {
  content: "\f137";
}
.icon-chevron-circle-right:before {
  content: "\f138";
}
.icon-chevron-circle-up:before {
  content: "\f139";
}
.icon-chevron-circle-down:before {
  content: "\f13a";
}
.icon-html5:before {
  content: "\f13b";
}
.icon-css3:before {
  content: "\f13c";
}
.icon-anchor:before {
  content: "\f13d";
}
.icon-unlock-alt:before {
  content: "\f13e";
}
.icon-bullseye:before {
  content: "\f140";
}
.icon-ellipsis-h:before {
  content: "\f141";
}
.icon-ellipsis-v:before {
  content: "\f142";
}
.icon-rss-square:before {
  content: "\f143";
}
.icon-play-circle:before {
  content: "\f144";
}
.icon-ticket:before {
  content: "\f145";
}
.icon-minus-square:before {
  content: "\f146";
}
.icon-minus-square-o:before {
  content: "\f147";
}
.icon-level-up:before {
  content: "\f148";
}
.icon-level-down:before {
  content: "\f149";
}
.icon-check-square:before {
  content: "\f14a";
}
.icon-pencil-square:before {
  content: "\f14b";
}
.icon-external-link-square:before {
  content: "\f14c";
}
.icon-share-square:before {
  content: "\f14d";
}
.icon-compass:before {
  content: "\f14e";
}
.icon-caret-square-o-down:before {
  content: "\f150";
}
.icon-toggle-down:before {
  content: "\f150";
}
.icon-caret-square-o-up:before {
  content: "\f151";
}
.icon-toggle-up:before {
  content: "\f151";
}
.icon-caret-square-o-right:before {
  content: "\f152";
}
.icon-toggle-right:before {
  content: "\f152";
}
.icon-eur:before {
  content: "\f153";
}
.icon-euro:before {
  content: "\f153";
}
.icon-gbp:before {
  content: "\f154";
}
.icon-dollar:before {
  content: "\f155";
}
.icon-usd:before {
  content: "\f155";
}
.icon-inr:before {
  content: "\f156";
}
.icon-rupee:before {
  content: "\f156";
}
.icon-cny:before {
  content: "\f157";
}
.icon-jpy:before {
  content: "\f157";
}
.icon-rmb:before {
  content: "\f157";
}
.icon-yen:before {
  content: "\f157";
}
.icon-rouble:before {
  content: "\f158";
}
.icon-rub:before {
  content: "\f158";
}
.icon-ruble:before {
  content: "\f158";
}
.icon-krw:before {
  content: "\f159";
}
.icon-won:before {
  content: "\f159";
}
.icon-bitcoin:before {
  content: "\f15a";
}
.icon-btc:before {
  content: "\f15a";
}
.icon-file:before {
  content: "\f15b";
}
.icon-file-text:before {
  content: "\f15c";
}
.icon-sort-alpha-asc:before {
  content: "\f15d";
}
.icon-sort-alpha-desc:before {
  content: "\f15e";
}
.icon-sort-amount-asc:before {
  content: "\f160";
}
.icon-sort-amount-desc:before {
  content: "\f161";
}
.icon-sort-numeric-asc:before {
  content: "\f162";
}
.icon-sort-numeric-desc:before {
  content: "\f163";
}
.icon-thumbs-up:before {
  content: "\f164";
}
.icon-thumbs-down:before {
  content: "\f165";
}
.icon-youtube-square:before {
  content: "\f166";
}
.icon-youtube:before {
  content: "\f167";
}
.icon-xing:before {
  content: "\f168";
}
.icon-xing-square:before {
  content: "\f169";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-dropbox:before {
  content: "\f16b";
}
.icon-stack-overflow:before {
  content: "\f16c";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-flickr:before {
  content: "\f16e";
}
.icon-adn:before {
  content: "\f170";
}
.icon-bitbucket:before {
  content: "\f171";
}
.icon-bitbucket-square:before {
  content: "\f172";
}
.icon-tumblr:before {
  content: "\f173";
}
.icon-tumblr-square:before {
  content: "\f174";
}
.icon-long-arrow-down:before {
  content: "\f175";
}
.icon-long-arrow-up:before {
  content: "\f176";
}
.icon-long-arrow-left:before {
  content: "\f177";
}
.icon-long-arrow-right:before {
  content: "\f178";
}
.icon-apple:before {
  content: "\f179";
}
.icon-windows:before {
  content: "\f17a";
}
.icon-android:before {
  content: "\f17b";
}
.icon-linux:before {
  content: "\f17c";
}
.icon-dribbble:before {
  content: "\f17d";
}
.icon-skype:before {
  content: "\f17e";
}
.icon-foursquare:before {
  content: "\f180";
}
.icon-trello:before {
  content: "\f181";
}
.icon-female:before {
  content: "\f182";
}
.icon-male:before {
  content: "\f183";
}
.icon-gittip:before {
  content: "\f184";
}
.icon-gratipay:before {
  content: "\f184";
}
.icon-sun-o:before {
  content: "\f185";
}
.icon-moon-o:before {
  content: "\f186";
}
.icon-archive:before {
  content: "\f187";
}
.icon-bug:before {
  content: "\f188";
}
.icon-vk:before {
  content: "\f189";
}
.icon-weibo:before {
  content: "\f18a";
}
.icon-renren:before {
  content: "\f18b";
}
.icon-pagelines:before {
  content: "\f18c";
}
.icon-stack-exchange:before {
  content: "\f18d";
}
.icon-arrow-circle-o-right:before {
  content: "\f18e";
}
.icon-arrow-circle-o-left:before {
  content: "\f190";
}
.icon-caret-square-o-left:before {
  content: "\f191";
}
.icon-toggle-left:before {
  content: "\f191";
}
.icon-dot-circle-o:before {
  content: "\f192";
}
.icon-wheelchair:before {
  content: "\f193";
}
.icon-vimeo-square:before {
  content: "\f194";
}
.icon-try:before {
  content: "\f195";
}
.icon-turkish-lira:before {
  content: "\f195";
}
.icon-plus-square-o:before {
  content: "\f196";
}
.icon-space-shuttle:before {
  content: "\f197";
}
.icon-slack:before {
  content: "\f198";
}
.icon-envelope-square:before {
  content: "\f199";
}
.icon-wordpress:before {
  content: "\f19a";
}
.icon-openid:before {
  content: "\f19b";
}
.icon-bank:before {
  content: "\f19c";
}
.icon-institution:before {
  content: "\f19c";
}
.icon-university:before {
  content: "\f19c";
}
.icon-graduation-cap:before {
  content: "\f19d";
}
.icon-mortar-board:before {
  content: "\f19d";
}
.icon-yahoo:before {
  content: "\f19e";
}
.icon-google:before {
  content: "\f1a0";
}
.icon-reddit:before {
  content: "\f1a1";
}
.icon-reddit-square:before {
  content: "\f1a2";
}
.icon-stumbleupon-circle:before {
  content: "\f1a3";
}
.icon-stumbleupon:before {
  content: "\f1a4";
}
.icon-delicious:before {
  content: "\f1a5";
}
.icon-digg:before {
  content: "\f1a6";
}
.icon-pied-piper-pp:before {
  content: "\f1a7";
}
.icon-pied-piper-alt:before {
  content: "\f1a8";
}
.icon-drupal:before {
  content: "\f1a9";
}
.icon-joomla:before {
  content: "\f1aa";
}
.icon-language:before {
  content: "\f1ab";
}
.icon-fax:before {
  content: "\f1ac";
}
.icon-building:before {
  content: "\f1ad";
}
.icon-child:before {
  content: "\f1ae";
}
.icon-paw:before {
  content: "\f1b0";
}
.icon-spoon:before {
  content: "\f1b1";
}
.icon-cube:before {
  content: "\f1b2";
}
.icon-cubes:before {
  content: "\f1b3";
}
.icon-behance:before {
  content: "\f1b4";
}
.icon-behance-square:before {
  content: "\f1b5";
}
.icon-steam:before {
  content: "\f1b6";
}
.icon-steam-square:before {
  content: "\f1b7";
}
.icon-recycle:before {
  content: "\f1b8";
}
.icon-automobile:before {
  content: "\f1b9";
}
.icon-car:before {
  content: "\f1b9";
}
.icon-cab:before {
  content: "\f1ba";
}
.icon-taxi:before {
  content: "\f1ba";
}
.icon-tree:before {
  content: "\f1bb";
}
.icon-spotify:before {
  content: "\f1bc";
}
.icon-deviantart:before {
  content: "\f1bd";
}
.icon-soundcloud:before {
  content: "\f1be";
}
.icon-database:before {
  content: "\f1c0";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-file-word-o:before {
  content: "\f1c2";
}
.icon-file-excel-o:before {
  content: "\f1c3";
}
.icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.icon-file-image-o:before {
  content: "\f1c5";
}
.icon-file-photo-o:before {
  content: "\f1c5";
}
.icon-file-picture-o:before {
  content: "\f1c5";
}
.icon-file-archive-o:before {
  content: "\f1c6";
}
.icon-file-zip-o:before {
  content: "\f1c6";
}
.icon-file-audio-o:before {
  content: "\f1c7";
}
.icon-file-sound-o:before {
  content: "\f1c7";
}
.icon-file-movie-o:before {
  content: "\f1c8";
}
.icon-file-video-o:before {
  content: "\f1c8";
}
.icon-file-code-o:before {
  content: "\f1c9";
}
.icon-vine:before {
  content: "\f1ca";
}
.icon-codepen:before {
  content: "\f1cb";
}
.icon-jsfiddle:before {
  content: "\f1cc";
}
.icon-life-bouy:before {
  content: "\f1cd";
}
.icon-life-buoy:before {
  content: "\f1cd";
}
.icon-life-ring:before {
  content: "\f1cd";
}
.icon-life-saver:before {
  content: "\f1cd";
}
.icon-support:before {
  content: "\f1cd";
}
.icon-circle-o-notch:before {
  content: "\f1ce";
}
.icon-ra:before {
  content: "\f1d0";
}
.icon-rebel:before {
  content: "\f1d0";
}
.icon-resistance:before {
  content: "\f1d0";
}
.icon-empire:before {
  content: "\f1d1";
}
.icon-ge:before {
  content: "\f1d1";
}
.icon-git-square:before {
  content: "\f1d2";
}
.icon-git:before {
  content: "\f1d3";
}
.icon-hacker-news:before {
  content: "\f1d4";
}
.icon-y-combinator-square:before {
  content: "\f1d4";
}
.icon-yc-square:before {
  content: "\f1d4";
}
.icon-tencent-weibo:before {
  content: "\f1d5";
}
.icon-qq:before {
  content: "\f1d6";
}
.icon-wechat:before {
  content: "\f1d7";
}
.icon-weixin:before {
  content: "\f1d7";
}
.icon-paper-plane:before {
  content: "\f1d8";
}
.icon-send:before {
  content: "\f1d8";
}
.icon-paper-plane-o:before {
  content: "\f1d9";
}
.icon-send-o:before {
  content: "\f1d9";
}
.icon-history:before {
  content: "\f1da";
}
.icon-circle-thin:before {
  content: "\f1db";
}
.icon-header:before {
  content: "\f1dc";
}
.icon-paragraph:before {
  content: "\f1dd";
}
.icon-sliders:before {
  content: "\f1de";
}
.icon-share-alt:before {
  content: "\f1e0";
}
.icon-share-alt-square:before {
  content: "\f1e1";
}
.icon-bomb:before {
  content: "\f1e2";
}
.icon-futbol-o:before {
  content: "\f1e3";
}
.icon-soccer-ball-o:before {
  content: "\f1e3";
}
.icon-tty:before {
  content: "\f1e4";
}
.icon-binoculars:before {
  content: "\f1e5";
}
.icon-plug:before {
  content: "\f1e6";
}
.icon-slideshare:before {
  content: "\f1e7";
}
.icon-twitch:before {
  content: "\f1e8";
}
.icon-yelp:before {
  content: "\f1e9";
}
.icon-newspaper-o:before {
  content: "\f1ea";
}
.icon-wifi:before {
  content: "\f1eb";
}
.icon-calculator:before {
  content: "\f1ec";
}
.icon-paypal:before {
  content: "\f1ed";
}
.icon-google-wallet:before {
  content: "\f1ee";
}
.icon-cc-visa:before {
  content: "\f1f0";
}
.icon-cc-mastercard:before {
  content: "\f1f1";
}
.icon-cc-discover:before {
  content: "\f1f2";
}
.icon-cc-amex:before {
  content: "\f1f3";
}
.icon-cc-paypal:before {
  content: "\f1f4";
}
.icon-cc-stripe:before {
  content: "\f1f5";
}
.icon-bell-slash:before {
  content: "\f1f6";
}
.icon-bell-slash-o:before {
  content: "\f1f7";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-copyright:before {
  content: "\f1f9";
}
.icon-at:before {
  content: "\f1fa";
}
.icon-eyedropper:before {
  content: "\f1fb";
}
.icon-paint-brush:before {
  content: "\f1fc";
}
.icon-birthday-cake:before {
  content: "\f1fd";
}
.icon-area-chart:before {
  content: "\f1fe";
}
.icon-pie-chart:before {
  content: "\f200";
}
.icon-line-chart:before {
  content: "\f201";
}
.icon-lastfm:before {
  content: "\f202";
}
.icon-lastfm-square:before {
  content: "\f203";
}
.icon-toggle-off:before {
  content: "\f204";
}
.icon-toggle-on:before {
  content: "\f205";
}
.icon-bicycle:before {
  content: "\f206";
}
.icon-bus:before {
  content: "\f207";
}
.icon-ioxhost:before {
  content: "\f208";
}
.icon-angellist:before {
  content: "\f209";
}
.icon-cc:before {
  content: "\f20a";
}
.icon-ils:before {
  content: "\f20b";
}
.icon-shekel:before {
  content: "\f20b";
}
.icon-sheqel:before {
  content: "\f20b";
}
.icon-meanpath:before {
  content: "\f20c";
}
.icon-buysellads:before {
  content: "\f20d";
}
.icon-connectdevelop:before {
  content: "\f20e";
}
.icon-dashcube:before {
  content: "\f210";
}
.icon-forumbee:before {
  content: "\f211";
}
.icon-leanpub:before {
  content: "\f212";
}
.icon-sellsy:before {
  content: "\f213";
}
.icon-shirtsinbulk:before {
  content: "\f214";
}
.icon-simplybuilt:before {
  content: "\f215";
}
.icon-skyatlas:before {
  content: "\f216";
}
.icon-cart-plus:before {
  content: "\f217";
}
.icon-cart-arrow-down:before {
  content: "\f218";
}
.icon-diamond:before {
  content: "\f219";
}
.icon-ship:before {
  content: "\f21a";
}
.icon-user-secret:before {
  content: "\f21b";
}
.icon-motorcycle:before {
  content: "\f21c";
}
.icon-street-view:before {
  content: "\f21d";
}
.icon-heartbeat:before {
  content: "\f21e";
}
.icon-venus:before {
  content: "\f221";
}
.icon-mars:before {
  content: "\f222";
}
.icon-mercury:before {
  content: "\f223";
}
.icon-intersex:before {
  content: "\f224";
}
.icon-transgender:before {
  content: "\f224";
}
.icon-transgender-alt:before {
  content: "\f225";
}
.icon-venus-double:before {
  content: "\f226";
}
.icon-mars-double:before {
  content: "\f227";
}
.icon-venus-mars:before {
  content: "\f228";
}
.icon-mars-stroke:before {
  content: "\f229";
}
.icon-mars-stroke-v:before {
  content: "\f22a";
}
.icon-mars-stroke-h:before {
  content: "\f22b";
}
.icon-neuter:before {
  content: "\f22c";
}
.icon-genderless:before {
  content: "\f22d";
}
.icon-facebook-official:before {
  content: "\f230";
}
.icon-pinterest-p:before {
  content: "\f231";
}
.icon-whatsapp:before {
  content: "\f232";
}
.icon-server:before {
  content: "\f233";
}
.icon-user-plus:before {
  content: "\f234";
}
.icon-user-times:before {
  content: "\f235";
}
.icon-bed:before {
  content: "\f236";
}
.icon-hotel:before {
  content: "\f236";
}
.icon-viacoin:before {
  content: "\f237";
}
.icon-train:before {
  content: "\f238";
}
.icon-subway:before {
  content: "\f239";
}
.icon-medium:before {
  content: "\f23a";
}
.icon-y-combinator:before {
  content: "\f23b";
}
.icon-yc:before {
  content: "\f23b";
}
.icon-optin-monster:before {
  content: "\f23c";
}
.icon-opencart:before {
  content: "\f23d";
}
.icon-expeditedssl:before {
  content: "\f23e";
}
.icon-battery:before {
  content: "\f240";
}
.icon-battery-4:before {
  content: "\f240";
}
.icon-battery-full:before {
  content: "\f240";
}
.icon-battery-3:before {
  content: "\f241";
}
.icon-battery-three-quarters:before {
  content: "\f241";
}
.icon-battery-2:before {
  content: "\f242";
}
.icon-battery-half:before {
  content: "\f242";
}
.icon-battery-1:before {
  content: "\f243";
}
.icon-battery-quarter:before {
  content: "\f243";
}
.icon-battery-0:before {
  content: "\f244";
}
.icon-battery-empty:before {
  content: "\f244";
}
.icon-mouse-pointer:before {
  content: "\f245";
}
.icon-i-cursor:before {
  content: "\f246";
}
.icon-object-group:before {
  content: "\f247";
}
.icon-object-ungroup:before {
  content: "\f248";
}
.icon-sticky-note:before {
  content: "\f249";
}
.icon-sticky-note-o:before {
  content: "\f24a";
}
.icon-cc-jcb:before {
  content: "\f24b";
}
.icon-cc-diners-club:before {
  content: "\f24c";
}
.icon-clone:before {
  content: "\f24d";
}
.icon-balance-scale:before {
  content: "\f24e";
}
.icon-hourglass-o:before {
  content: "\f250";
}
.icon-hourglass-1:before {
  content: "\f251";
}
.icon-hourglass-start:before {
  content: "\f251";
}
.icon-hourglass-2:before {
  content: "\f252";
}
.icon-hourglass-half:before {
  content: "\f252";
}
.icon-hourglass-3:before {
  content: "\f253";
}
.icon-hourglass-end:before {
  content: "\f253";
}
.icon-hourglass:before {
  content: "\f254";
}
.icon-hand-grab-o:before {
  content: "\f255";
}
.icon-hand-rock-o:before {
  content: "\f255";
}
.icon-hand-paper-o:before {
  content: "\f256";
}
.icon-hand-stop-o:before {
  content: "\f256";
}
.icon-hand-scissors-o:before {
  content: "\f257";
}
.icon-hand-lizard-o:before {
  content: "\f258";
}
.icon-hand-spock-o:before {
  content: "\f259";
}
.icon-hand-pointer-o:before {
  content: "\f25a";
}
.icon-hand-peace-o:before {
  content: "\f25b";
}
.icon-trademark:before {
  content: "\f25c";
}
.icon-registered:before {
  content: "\f25d";
}
.icon-creative-commons:before {
  content: "\f25e";
}
.icon-gg:before {
  content: "\f260";
}
.icon-gg-circle:before {
  content: "\f261";
}
.icon-tripadvisor:before {
  content: "\f262";
}
.icon-odnoklassniki:before {
  content: "\f263";
}
.icon-odnoklassniki-square:before {
  content: "\f264";
}
.icon-get-pocket:before {
  content: "\f265";
}
.icon-wikipedia-w:before {
  content: "\f266";
}
.icon-safari:before {
  content: "\f267";
}
.icon-chrome:before {
  content: "\f268";
}
.icon-firefox:before {
  content: "\f269";
}
.icon-opera:before {
  content: "\f26a";
}
.icon-internet-explorer:before {
  content: "\f26b";
}
.icon-television:before {
  content: "\f26c";
}
.icon-tv:before {
  content: "\f26c";
}
.icon-contao:before {
  content: "\f26d";
}
.icon-500px:before {
  content: "\f26e";
}
.icon-amazon:before {
  content: "\f270";
}
.icon-calendar-plus-o:before {
  content: "\f271";
}
.icon-calendar-minus-o:before {
  content: "\f272";
}
.icon-calendar-times-o:before {
  content: "\f273";
}
.icon-calendar-check-o:before {
  content: "\f274";
}
.icon-industry:before {
  content: "\f275";
}
.icon-map-pin:before {
  content: "\f276";
}
.icon-map-signs:before {
  content: "\f277";
}
.icon-map-o:before {
  content: "\f278";
}
.icon-map:before {
  content: "\f279";
}
.icon-commenting:before {
  content: "\f27a";
}
.icon-commenting-o:before {
  content: "\f27b";
}
.icon-houzz:before {
  content: "\f27c";
}
.icon-vimeo:before {
  content: "\f27d";
}
.icon-black-tie:before {
  content: "\f27e";
}
.icon-fonticons:before {
  content: "\f280";
}
.icon-reddit-alien:before {
  content: "\f281";
}
.icon-edge:before {
  content: "\f282";
}
.icon-credit-card-alt:before {
  content: "\f283";
}
.icon-codiepie:before {
  content: "\f284";
}
.icon-modx:before {
  content: "\f285";
}
.icon-fort-awesome:before {
  content: "\f286";
}
.icon-usb:before {
  content: "\f287";
}
.icon-product-hunt:before {
  content: "\f288";
}
.icon-mixcloud:before {
  content: "\f289";
}
.icon-scribd:before {
  content: "\f28a";
}
.icon-pause-circle:before {
  content: "\f28b";
}
.icon-pause-circle-o:before {
  content: "\f28c";
}
.icon-stop-circle:before {
  content: "\f28d";
}
.icon-stop-circle-o:before {
  content: "\f28e";
}
.icon-shopping-bag:before {
  content: "\f290";
}
.icon-shopping-basket:before {
  content: "\f291";
}
.icon-hashtag:before {
  content: "\f292";
}
.icon-bluetooth:before {
  content: "\f293";
}
.icon-bluetooth-b:before {
  content: "\f294";
}
.icon-percent:before {
  content: "\f295";
}
.icon-gitlab:before {
  content: "\f296";
}
.icon-wpbeginner:before {
  content: "\f297";
}
.icon-wpforms:before {
  content: "\f298";
}
.icon-envira:before {
  content: "\f299";
}
.icon-universal-access:before {
  content: "\f29a";
}
.icon-wheelchair-alt:before {
  content: "\f29b";
}
.icon-question-circle-o:before {
  content: "\f29c";
}
.icon-blind:before {
  content: "\f29d";
}
.icon-audio-description:before {
  content: "\f29e";
}
.icon-volume-control-phone:before {
  content: "\f2a0";
}
.icon-braille:before {
  content: "\f2a1";
}
.icon-assistive-listening-systems:before {
  content: "\f2a2";
}
.icon-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.icon-asl-interpreting:before {
  content: "\f2a3";
}
.icon-deaf:before {
  content: "\f2a4";
}
.icon-deafness:before {
  content: "\f2a4";
}
.icon-hard-of-hearing:before {
  content: "\f2a4";
}
.icon-glide:before {
  content: "\f2a5";
}
.icon-glide-g:before {
  content: "\f2a6";
}
.icon-sign-language:before {
  content: "\f2a7";
}
.icon-signing:before {
  content: "\f2a7";
}
.icon-low-vision:before {
  content: "\f2a8";
}
.icon-viadeo:before {
  content: "\f2a9";
}
.icon-viadeo-square:before {
  content: "\f2aa";
}
.icon-snapchat:before {
  content: "\f2ab";
}
.icon-snapchat-ghost:before {
  content: "\f2ac";
}
.icon-snapchat-square:before {
  content: "\f2ad";
}
.icon-pied-piper:before {
  content: "\f2ae";
}
.icon-first-order:before {
  content: "\f2b0";
}
.icon-yoast:before {
  content: "\f2b1";
}
.icon-themeisle:before {
  content: "\f2b2";
}
.icon-google-plus-circle:before {
  content: "\f2b3";
}
.icon-google-plus-official:before {
  content: "\f2b3";
}
.icon-fa:before {
  content: "\f2b4";
}
.icon-font-awesome:before {
  content: "\f2b4";
}
.icon-handshake-o:before {
  content: "\f2b5";
}
.icon-envelope-open:before {
  content: "\f2b6";
}
.icon-envelope-open-o:before {
  content: "\f2b7";
}
.icon-linode:before {
  content: "\f2b8";
}
.icon-address-book:before {
  content: "\f2b9";
}
.icon-address-book-o:before {
  content: "\f2ba";
}
.icon-address-card:before {
  content: "\f2bb";
}
.icon-vcard:before {
  content: "\f2bb";
}
.icon-address-card-o:before {
  content: "\f2bc";
}
.icon-vcard-o:before {
  content: "\f2bc";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-user-circle-o:before {
  content: "\f2be";
}
.icon-user-o:before {
  content: "\f2c0";
}
.icon-id-badge:before {
  content: "\f2c1";
}
.icon-drivers-license:before {
  content: "\f2c2";
}
.icon-id-card:before {
  content: "\f2c2";
}
.icon-drivers-license-o:before {
  content: "\f2c3";
}
.icon-id-card-o:before {
  content: "\f2c3";
}
.icon-quora:before {
  content: "\f2c4";
}
.icon-free-code-camp:before {
  content: "\f2c5";
}
.icon-telegram:before {
  content: "\f2c6";
}
.icon-thermometer:before {
  content: "\f2c7";
}
.icon-thermometer-4:before {
  content: "\f2c7";
}
.icon-thermometer-full:before {
  content: "\f2c7";
}
.icon-thermometer-3:before {
  content: "\f2c8";
}
.icon-thermometer-three-quarters:before {
  content: "\f2c8";
}
.icon-thermometer-2:before {
  content: "\f2c9";
}
.icon-thermometer-half:before {
  content: "\f2c9";
}
.icon-thermometer-1:before {
  content: "\f2ca";
}
.icon-thermometer-quarter:before {
  content: "\f2ca";
}
.icon-thermometer-0:before {
  content: "\f2cb";
}
.icon-thermometer-empty:before {
  content: "\f2cb";
}
.icon-shower:before {
  content: "\f2cc";
}
.icon-bath:before {
  content: "\f2cd";
}
.icon-bathtub:before {
  content: "\f2cd";
}
.icon-s15:before {
  content: "\f2cd";
}
.icon-podcast:before {
  content: "\f2ce";
}
.icon-window-maximize:before {
  content: "\f2d0";
}
.icon-window-minimize:before {
  content: "\f2d1";
}
.icon-window-restore:before {
  content: "\f2d2";
}
.icon-times-rectangle:before {
  content: "\f2d3";
}
.icon-window-close:before {
  content: "\f2d3";
}
.icon-times-rectangle-o:before {
  content: "\f2d4";
}
.icon-window-close-o:before {
  content: "\f2d4";
}
.icon-bandcamp:before {
  content: "\f2d5";
}
.icon-grav:before {
  content: "\f2d6";
}
.icon-etsy:before {
  content: "\f2d7";
}
.icon-imdb:before {
  content: "\f2d8";
}
.icon-ravelry:before {
  content: "\f2d9";
}
.icon-eercast:before {
  content: "\f2da";
}
.icon-microchip:before {
  content: "\f2db";
}
.icon-snowflake-o:before {
  content: "\f2dc";
}
.icon-superpowers:before {
  content: "\f2dd";
}
.icon-wpexplorer:before {
  content: "\f2de";
}
.icon-meetup:before {
  content: "\f2e0";
}
.icon-weather_aquarius:before {
  content: "\e900";
}
.icon-weather_aries:before {
  content: "\e901";
}
.icon-weather_cancer:before {
  content: "\e902";
}
.icon-weather_capricorn:before {
  content: "\e903";
}
.icon-weather_cloud_drop:before {
  content: "\e904";
}
.icon-weather_cloud_lightning:before {
  content: "\e905";
}
.icon-weather_cloud_snowflake:before {
  content: "\e906";
}
.icon-weather_cloud:before {
  content: "\e907";
}
.icon-weather_downpour_fullmoon:before {
  content: "\e908";
}
.icon-weather_downpour_halfmoon:before {
  content: "\e909";
}
.icon-weather_downpour_sun:before {
  content: "\e90a";
}
.icon-weather_drop:before {
  content: "\e90b";
}
.icon-weather_first_quarter:before {
  content: "\e90c";
}
.icon-weather_fog_fullmoon:before {
  content: "\e90d";
}
.icon-weather_fog_halfmoon:before {
  content: "\e90e";
}
.icon-weather_fog_sun:before {
  content: "\e90f";
}
.icon-weather_fog:before {
  content: "\e910";
}
.icon-weather_fullmoon:before {
  content: "\e911";
}
.icon-weather_gemini:before {
  content: "\e912";
}
.icon-weather_hail_fullmoon:before {
  content: "\e913";
}
.icon-weather_hail_halfmoon:before {
  content: "\e914";
}
.icon-weather_hail_sun:before {
  content: "\e915";
}
.icon-weather_hail:before {
  content: "\e916";
}
.icon-weather_last_quarter:before {
  content: "\e917";
}
.icon-weather_leo:before {
  content: "\e918";
}
.icon-weather_libra:before {
  content: "\e919";
}
.icon-weather_lightning:before {
  content: "\e91a";
}
.icon-weather_mistyrain_fullmoon:before {
  content: "\e91b";
}
.icon-weather_mistyrain_halfmoon:before {
  content: "\e91c";
}
.icon-weather_mistyrain_sun:before {
  content: "\e91d";
}
.icon-weather_mistyrain:before {
  content: "\e91e";
}
.icon-weather_moon:before {
  content: "\e91f";
}
.icon-weather_moondown_full:before {
  content: "\e920";
}
.icon-weather_moondown_half:before {
  content: "\e921";
}
.icon-weather_moonset_full:before {
  content: "\e922";
}
.icon-weather_moonset_half:before {
  content: "\e923";
}
.icon-weather_move2:before {
  content: "\e924";
}
.icon-weather_newmoon:before {
  content: "\e925";
}
.icon-weather_pisces:before {
  content: "\e926";
}
.icon-weather_rain_fullmoon:before {
  content: "\e927";
}
.icon-weather_rain_halfmoon:before {
  content: "\e928";
}
.icon-weather_rain_sun:before {
  content: "\e929";
}
.icon-weather_rain:before {
  content: "\e92a";
}
.icon-weather_sagittarius:before {
  content: "\e92b";
}
.icon-weather_scorpio:before {
  content: "\e92c";
}
.icon-weather_snow_fullmoon:before {
  content: "\e92d";
}
.icon-weather_snow_halfmoon:before {
  content: "\e92e";
}
.icon-weather_snow_sun:before {
  content: "\e92f";
}
.icon-weather_snow:before {
  content: "\e930";
}
.icon-weather_snowflake:before {
  content: "\e931";
}
.icon-weather_star:before {
  content: "\e932";
}
.icon-weather_storm_fullmoon:before {
  content: "\e933";
}
.icon-weather_storm_halfmoon:before {
  content: "\e934";
}
.icon-weather_storm_sun:before {
  content: "\e935";
}
.icon-weather_storm-11:before {
  content: "\e936";
}
.icon-weather_storm-32:before {
  content: "\e937";
}
.icon-weather_sun:before {
  content: "\e938";
}
.icon-weather_sundown:before {
  content: "\e939";
}
.icon-weather_sunset:before {
  content: "\e93a";
}
.icon-weather_taurus:before {
  content: "\e93b";
}
.icon-weather_tempest_fullmoon:before {
  content: "\e93c";
}
.icon-weather_tempest_halfmoon:before {
  content: "\e93d";
}
.icon-weather_tempest_sun:before {
  content: "\e93e";
}
.icon-weather_tempest:before {
  content: "\e93f";
}
.icon-weather_variable_fullmoon:before {
  content: "\e940";
}
.icon-weather_variable_halfmoon:before {
  content: "\e941";
}
.icon-weather_variable_sun:before {
  content: "\e942";
}
.icon-weather_virgo:before {
  content: "\e943";
}
.icon-weather_waning_cresent:before {
  content: "\e944";
}
.icon-weather_waning_gibbous:before {
  content: "\e945";
}
.icon-weather_waxing_cresent:before {
  content: "\e946";
}
.icon-weather_waxing_gibbous:before {
  content: "\e947";
}
.icon-weather_wind_E:before {
  content: "\e948";
}
.icon-weather_wind_fullmoon:before {
  content: "\e949";
}
.icon-weather_wind_halfmoon:before {
  content: "\e94a";
}
.icon-weather_wind_N:before {
  content: "\e94b";
}
.icon-weather_wind_NE:before {
  content: "\e94c";
}
.icon-weather_wind_NW:before {
  content: "\e94d";
}
.icon-weather_wind_S:before {
  content: "\e94e";
}
.icon-weather_wind_SE:before {
  content: "\e94f";
}
.icon-weather_wind_sun:before {
  content: "\e950";
}
.icon-weather_wind_SW:before {
  content: "\e951";
}
.icon-weather_wind_W:before {
  content: "\e952";
}
.icon-weather_wind:before {
  content: "\e953";
}
.icon-weather_windgust:before {
  content: "\e954";
}
.icon-software_add_vectorpoint:before {
  content: "\e955";
}
.icon-software_box_oval:before {
  content: "\e956";
}
.icon-software_box_polygon:before {
  content: "\e957";
}
.icon-software_box_rectangle:before {
  content: "\e958";
}
.icon-software_box_roundedrectangle:before {
  content: "\e959";
}
.icon-software_character:before {
  content: "\e95a";
}
.icon-software_crop:before {
  content: "\e95b";
}
.icon-software_eyedropper:before {
  content: "\e95c";
}
.icon-software_font_allcaps:before {
  content: "\e95d";
}
.icon-software_font_baseline_shift:before {
  content: "\e95e";
}
.icon-software_font_horizontal_scale:before {
  content: "\e95f";
}
.icon-software_font_kerning:before {
  content: "\e960";
}
.icon-software_font_leading:before {
  content: "\e961";
}
.icon-software_font_size:before {
  content: "\e962";
}
.icon-software_font_smallcapital:before {
  content: "\e963";
}
.icon-software_font_smallcaps:before {
  content: "\e964";
}
.icon-software_font_strikethrough:before {
  content: "\e965";
}
.icon-software_font_tracking:before {
  content: "\e966";
}
.icon-software_font_underline:before {
  content: "\e967";
}
.icon-software_font_vertical_scale:before {
  content: "\e968";
}
.icon-software_horizontal_align_center:before {
  content: "\e969";
}
.icon-software_horizontal_align_right:before {
  content: "\e96a";
}
.icon-software_horizontal_distribute_center:before {
  content: "\e96b";
}
.icon-software_horizontal_distribute_left:before {
  content: "\e96c";
}
.icon-software_horizontal_distribute_right:before {
  content: "\e96d";
}
.icon-software_indent_firstline:before {
  content: "\e96e";
}
.icon-software_indent_left:before {
  content: "\e96f";
}
.icon-software_indent_right:before {
  content: "\e970";
}
.icon-software_lasso:before {
  content: "\e971";
}
.icon-software_layers1:before {
  content: "\e972";
}
.icon-software_layers2:before {
  content: "\e973";
}
.icon-software_layout_2columns:before {
  content: "\e974";
}
.icon-software_layout_3columns:before {
  content: "\e975";
}
.icon-software_layout_4boxes:before {
  content: "\e976";
}
.icon-software_layout_4columns:before {
  content: "\e977";
}
.icon-software_layout_4lines:before {
  content: "\e978";
}
.icon-software_layout_header_2columns:before {
  content: "\e979";
}
.icon-software_layout_header_3columns:before {
  content: "\e97a";
}
.icon-software_layout_header_4boxes:before {
  content: "\e97b";
}
.icon-software_layout_header_4columns:before {
  content: "\e97c";
}
.icon-software_layout_header_complex:before {
  content: "\e97d";
}
.icon-software_layout_header_complex2:before {
  content: "\e97e";
}
.icon-software_layout_header_complex3:before {
  content: "\e97f";
}
.icon-software_layout_header_complex4:before {
  content: "\e980";
}
.icon-software_layout_header_sideleft:before {
  content: "\e981";
}
.icon-software_layout_header_sideright:before {
  content: "\e982";
}
.icon-software_layout_header:before {
  content: "\e983";
}
.icon-software_layout_sidebar_left:before {
  content: "\e984";
}
.icon-software_layout_sidebar_right:before {
  content: "\e985";
}
.icon-software_layout-8boxes:before {
  content: "\e986";
}
.icon-software_layout:before {
  content: "\e987";
}
.icon-software_magnete:before {
  content: "\e988";
}
.icon-software_pages:before {
  content: "\e989";
}
.icon-software_paintbrush:before {
  content: "\e98a";
}
.icon-software_paintbucket:before {
  content: "\e98b";
}
.icon-software_paintroller:before {
  content: "\e98c";
}
.icon-software_paragraph_align_left:before {
  content: "\e98d";
}
.icon-software_paragraph_align_right:before {
  content: "\e98e";
}
.icon-software_paragraph_center:before {
  content: "\e98f";
}
.icon-software_paragraph_justify_all:before {
  content: "\e990";
}
.icon-software_paragraph_justify_center:before {
  content: "\e991";
}
.icon-software_paragraph_justify_left:before {
  content: "\e992";
}
.icon-software_paragraph_justify_right:before {
  content: "\e993";
}
.icon-software_paragraph_space_after:before {
  content: "\e994";
}
.icon-software_paragraph_space_before:before {
  content: "\e995";
}
.icon-software_paragraph:before {
  content: "\e996";
}
.icon-software_pathfinder_exclude:before {
  content: "\e997";
}
.icon-software_pathfinder_intersect:before {
  content: "\e998";
}
.icon-software_pathfinder_subtract:before {
  content: "\e999";
}
.icon-software_pathfinder_unite:before {
  content: "\e99a";
}
.icon-software_pen_add:before {
  content: "\e99b";
}
.icon-software_pen_remove:before {
  content: "\e99c";
}
.icon-software_pen:before {
  content: "\e99d";
}
.icon-software_pencil:before {
  content: "\e99e";
}
.icon-software_polygonallasso:before {
  content: "\e99f";
}
.icon-software_reflect_horizontal:before {
  content: "\e9a0";
}
.icon-software_reflect_vertical:before {
  content: "\e9a1";
}
.icon-software_remove_vectorpoint:before {
  content: "\e9a2";
}
.icon-software_scale_expand:before {
  content: "\e9a3";
}
.icon-software_scale_reduce:before {
  content: "\e9a4";
}
.icon-software_selection_oval:before {
  content: "\e9a5";
}
.icon-software_selection_polygon:before {
  content: "\e9a6";
}
.icon-software_selection_rectangle:before {
  content: "\e9a7";
}
.icon-software_selection_roundedrectangle:before {
  content: "\e9a8";
}
.icon-software_shape_oval:before {
  content: "\e9a9";
}
.icon-software_shape_polygon:before {
  content: "\e9aa";
}
.icon-software_shape_rectangle:before {
  content: "\e9ab";
}
.icon-software_shape_roundedrectangle:before {
  content: "\e9ac";
}
.icon-software_slice:before {
  content: "\e9ad";
}
.icon-software_transform_bezier:before {
  content: "\e9ae";
}
.icon-software_vector_box:before {
  content: "\e9af";
}
.icon-software_vector_composite:before {
  content: "\e9b0";
}
.icon-software_vector_line:before {
  content: "\e9b1";
}
.icon-software_vertical_align_bottom:before {
  content: "\e9b2";
}
.icon-software_vertical_align_center:before {
  content: "\e9b3";
}
.icon-software_vertical_align_top:before {
  content: "\e9b4";
}
.icon-software_vertical_distribute_bottom:before {
  content: "\e9b5";
}
.icon-software_vertical_distribute_center:before {
  content: "\e9b6";
}
.icon-software_vertical_distribute_top:before {
  content: "\e9b7";
}
.icon-software-horizontal_align_left:before {
  content: "\e9b8";
}
.icon-music_beginning_button:before {
  content: "\e9b9";
}
.icon-music_bell:before {
  content: "\e9ba";
}
.icon-music_cd:before {
  content: "\e9bb";
}
.icon-music_diapason:before {
  content: "\e9bc";
}
.icon-music_eject_button:before {
  content: "\e9bd";
}
.icon-music_end_button:before {
  content: "\e9be";
}
.icon-music_fastforward_button:before {
  content: "\e9bf";
}
.icon-music_headphones:before {
  content: "\e9c0";
}
.icon-music_ipod:before {
  content: "\e9c1";
}
.icon-music_loudspeaker:before {
  content: "\e9c2";
}
.icon-music_microphone_old:before {
  content: "\e9c3";
}
.icon-music_microphone:before {
  content: "\e9c4";
}
.icon-music_mixer:before {
  content: "\e9c5";
}
.icon-music_mute:before {
  content: "\e9c6";
}
.icon-music_note_multiple:before {
  content: "\e9c7";
}
.icon-music_note_single:before {
  content: "\e9c8";
}
.icon-music_pause_button:before {
  content: "\e9c9";
}
.icon-music_play_button:before {
  content: "\e9ca";
}
.icon-music_playlist:before {
  content: "\e9cb";
}
.icon-music_radio_ghettoblaster:before {
  content: "\e9cc";
}
.icon-music_radio_portable:before {
  content: "\e9cd";
}
.icon-music_record:before {
  content: "\e9ce";
}
.icon-music_recordplayer:before {
  content: "\e9cf";
}
.icon-music_repeat_button:before {
  content: "\e9d0";
}
.icon-music_rewind_button:before {
  content: "\e9d1";
}
.icon-music_shuffle_button:before {
  content: "\e9d2";
}
.icon-music_stop_button:before {
  content: "\e9d3";
}
.icon-music_tape:before {
  content: "\e9d4";
}
.icon-music_volume_down:before {
  content: "\e9d5";
}
.icon-music_volume_up:before {
  content: "\e9d6";
}
.icon-ecommerce_bag_check:before {
  content: "\e9d7";
}
.icon-ecommerce_bag_cloud:before {
  content: "\e9d8";
}
.icon-ecommerce_bag_download:before {
  content: "\e9d9";
}
.icon-ecommerce_bag_minus:before {
  content: "\e9da";
}
.icon-ecommerce_bag_plus:before {
  content: "\e9db";
}
.icon-ecommerce_bag_refresh:before {
  content: "\e9dc";
}
.icon-ecommerce_bag_remove:before {
  content: "\e9dd";
}
.icon-ecommerce_bag_search:before {
  content: "\e9de";
}
.icon-ecommerce_bag_upload:before {
  content: "\e9df";
}
.icon-ecommerce_bag:before {
  content: "\e9e0";
}
.icon-ecommerce_banknote:before {
  content: "\e9e1";
}
.icon-ecommerce_banknotes:before {
  content: "\e9e2";
}
.icon-ecommerce_basket_check:before {
  content: "\e9e3";
}
.icon-ecommerce_basket_cloud:before {
  content: "\e9e4";
}
.icon-ecommerce_basket_download:before {
  content: "\e9e5";
}
.icon-ecommerce_basket_minus:before {
  content: "\e9e6";
}
.icon-ecommerce_basket_plus:before {
  content: "\e9e7";
}
.icon-ecommerce_basket_refresh:before {
  content: "\e9e8";
}
.icon-ecommerce_basket_remove:before {
  content: "\e9e9";
}
.icon-ecommerce_basket_search:before {
  content: "\e9ea";
}
.icon-ecommerce_basket_upload:before {
  content: "\e9eb";
}
.icon-ecommerce_basket:before {
  content: "\e9ec";
}
.icon-ecommerce_bath:before {
  content: "\e9ed";
}
.icon-ecommerce_cart_check:before {
  content: "\e9ee";
}
.icon-ecommerce_cart_cloud:before {
  content: "\e9ef";
}
.icon-ecommerce_cart_content:before {
  content: "\e9f0";
}
.icon-ecommerce_cart_download:before {
  content: "\e9f1";
}
.icon-ecommerce_cart_minus:before {
  content: "\e9f2";
}
.icon-ecommerce_cart_plus:before {
  content: "\e9f3";
}
.icon-ecommerce_cart_refresh:before {
  content: "\e9f4";
}
.icon-ecommerce_cart_remove:before {
  content: "\e9f5";
}
.icon-ecommerce_cart_search:before {
  content: "\e9f6";
}
.icon-ecommerce_cart_upload:before {
  content: "\e9f7";
}
.icon-ecommerce_cart:before {
  content: "\e9f8";
}
.icon-ecommerce_cent:before {
  content: "\e9f9";
}
.icon-ecommerce_colon:before {
  content: "\e9fa";
}
.icon-ecommerce_creditcard:before {
  content: "\e9fb";
}
.icon-ecommerce_diamond:before {
  content: "\e9fc";
}
.icon-ecommerce_dollar:before {
  content: "\e9fd";
}
.icon-ecommerce_euro:before {
  content: "\e9fe";
}
.icon-ecommerce_franc:before {
  content: "\e9ff";
}
.icon-ecommerce_gift:before {
  content: "\ea00";
}
.icon-ecommerce_graph_decrease:before {
  content: "\ea01";
}
.icon-ecommerce_graph_increase:before {
  content: "\ea02";
}
.icon-ecommerce_graph1:before {
  content: "\ea03";
}
.icon-ecommerce_graph2:before {
  content: "\ea04";
}
.icon-ecommerce_graph3:before {
  content: "\ea05";
}
.icon-ecommerce_guarani:before {
  content: "\ea06";
}
.icon-ecommerce_kips:before {
  content: "\ea07";
}
.icon-ecommerce_lira:before {
  content: "\ea08";
}
.icon-ecommerce_megaphone:before {
  content: "\ea09";
}
.icon-ecommerce_money:before {
  content: "\ea0a";
}
.icon-ecommerce_naira:before {
  content: "\ea0b";
}
.icon-ecommerce_pesos:before {
  content: "\ea0c";
}
.icon-ecommerce_pound:before {
  content: "\ea0d";
}
.icon-ecommerce_receipt_bath:before {
  content: "\ea0e";
}
.icon-ecommerce_receipt_cent:before {
  content: "\ea0f";
}
.icon-ecommerce_receipt_dollar:before {
  content: "\ea10";
}
.icon-ecommerce_receipt_euro:before {
  content: "\ea11";
}
.icon-ecommerce_receipt_franc:before {
  content: "\ea12";
}
.icon-ecommerce_receipt_guarani:before {
  content: "\ea13";
}
.icon-ecommerce_receipt_kips:before {
  content: "\ea14";
}
.icon-ecommerce_receipt_lira:before {
  content: "\ea15";
}
.icon-ecommerce_receipt_naira:before {
  content: "\ea16";
}
.icon-ecommerce_receipt_pesos:before {
  content: "\ea17";
}
.icon-ecommerce_receipt_pound:before {
  content: "\ea18";
}
.icon-ecommerce_receipt_rublo:before {
  content: "\ea19";
}
.icon-ecommerce_receipt_rupee:before {
  content: "\ea1a";
}
.icon-ecommerce_receipt_tugrik:before {
  content: "\ea1b";
}
.icon-ecommerce_receipt_won:before {
  content: "\ea1c";
}
.icon-ecommerce_receipt_yen:before {
  content: "\ea1d";
}
.icon-ecommerce_receipt_yen2:before {
  content: "\ea1e";
}
.icon-ecommerce_receipt:before {
  content: "\ea1f";
}
.icon-ecommerce_recept_colon:before {
  content: "\ea20";
}
.icon-ecommerce_rublo:before {
  content: "\ea21";
}
.icon-ecommerce_rupee:before {
  content: "\ea22";
}
.icon-ecommerce_safe:before {
  content: "\ea23";
}
.icon-ecommerce_sale:before {
  content: "\ea24";
}
.icon-ecommerce_sales:before {
  content: "\ea25";
}
.icon-ecommerce_ticket:before {
  content: "\ea26";
}
.icon-ecommerce_tugriks:before {
  content: "\ea27";
}
.icon-ecommerce_wallet:before {
  content: "\ea28";
}
.icon-ecommerce_won:before {
  content: "\ea29";
}
.icon-ecommerce_yen:before {
  content: "\ea2a";
}
.icon-ecommerce_yen2:before {
  content: "\ea2b";
}
.icon-basic_elaboration_bookmark_checck:before {
  content: "\ea2c";
}
.icon-basic_elaboration_bookmark_minus:before {
  content: "\ea2d";
}
.icon-basic_elaboration_bookmark_plus:before {
  content: "\ea2e";
}
.icon-basic_elaboration_bookmark_remove:before {
  content: "\ea2f";
}
.icon-basic_elaboration_briefcase_check:before {
  content: "\ea30";
}
.icon-basic_elaboration_briefcase_download:before {
  content: "\ea31";
}
.icon-basic_elaboration_briefcase_flagged:before {
  content: "\ea32";
}
.icon-basic_elaboration_briefcase_minus:before {
  content: "\ea33";
}
.icon-basic_elaboration_briefcase_plus:before {
  content: "\ea34";
}
.icon-basic_elaboration_briefcase_refresh:before {
  content: "\ea35";
}
.icon-basic_elaboration_briefcase_remove:before {
  content: "\ea36";
}
.icon-basic_elaboration_briefcase_search:before {
  content: "\ea37";
}
.icon-basic_elaboration_briefcase_star:before {
  content: "\ea38";
}
.icon-basic_elaboration_briefcase_upload:before {
  content: "\ea39";
}
.icon-basic_elaboration_browser_check:before {
  content: "\ea3a";
}
.icon-basic_elaboration_browser_download:before {
  content: "\ea3b";
}
.icon-basic_elaboration_browser_minus:before {
  content: "\ea3c";
}
.icon-basic_elaboration_browser_plus:before {
  content: "\ea3d";
}
.icon-basic_elaboration_browser_refresh:before {
  content: "\ea3e";
}
.icon-basic_elaboration_browser_remove:before {
  content: "\ea3f";
}
.icon-basic_elaboration_browser_search:before {
  content: "\ea40";
}
.icon-basic_elaboration_browser_star:before {
  content: "\ea41";
}
.icon-basic_elaboration_browser_upload:before {
  content: "\ea42";
}
.icon-basic_elaboration_calendar_check:before {
  content: "\ea43";
}
.icon-basic_elaboration_calendar_cloud:before {
  content: "\ea44";
}
.icon-basic_elaboration_calendar_download:before {
  content: "\ea45";
}
.icon-basic_elaboration_calendar_empty:before {
  content: "\ea46";
}
.icon-basic_elaboration_calendar_flagged:before {
  content: "\ea47";
}
.icon-basic_elaboration_calendar_heart:before {
  content: "\ea48";
}
.icon-basic_elaboration_calendar_minus:before {
  content: "\ea49";
}
.icon-basic_elaboration_calendar_next:before {
  content: "\ea4a";
}
.icon-basic_elaboration_calendar_noaccess:before {
  content: "\ea4b";
}
.icon-basic_elaboration_calendar_pencil:before {
  content: "\ea4c";
}
.icon-basic_elaboration_calendar_plus:before {
  content: "\ea4d";
}
.icon-basic_elaboration_calendar_previous:before {
  content: "\ea4e";
}
.icon-basic_elaboration_calendar_refresh:before {
  content: "\ea4f";
}
.icon-basic_elaboration_calendar_remove:before {
  content: "\ea50";
}
.icon-basic_elaboration_calendar_search:before {
  content: "\ea51";
}
.icon-basic_elaboration_calendar_star:before {
  content: "\ea52";
}
.icon-basic_elaboration_calendar_upload:before {
  content: "\ea53";
}
.icon-basic_elaboration_cloud_check:before {
  content: "\ea54";
}
.icon-basic_elaboration_cloud_download:before {
  content: "\ea55";
}
.icon-basic_elaboration_cloud_minus:before {
  content: "\ea56";
}
.icon-basic_elaboration_cloud_noaccess:before {
  content: "\ea57";
}
.icon-basic_elaboration_cloud_plus:before {
  content: "\ea58";
}
.icon-basic_elaboration_cloud_refresh:before {
  content: "\ea59";
}
.icon-basic_elaboration_cloud_remove:before {
  content: "\ea5a";
}
.icon-basic_elaboration_cloud_search:before {
  content: "\ea5b";
}
.icon-basic_elaboration_cloud_upload:before {
  content: "\ea5c";
}
.icon-basic_elaboration_document_check:before {
  content: "\ea5d";
}
.icon-basic_elaboration_document_cloud:before {
  content: "\ea5e";
}
.icon-basic_elaboration_document_download:before {
  content: "\ea5f";
}
.icon-basic_elaboration_document_flagged:before {
  content: "\ea60";
}
.icon-basic_elaboration_document_graph:before {
  content: "\ea61";
}
.icon-basic_elaboration_document_heart:before {
  content: "\ea62";
}
.icon-basic_elaboration_document_minus:before {
  content: "\ea63";
}
.icon-basic_elaboration_document_next:before {
  content: "\ea64";
}
.icon-basic_elaboration_document_noaccess:before {
  content: "\ea65";
}
.icon-basic_elaboration_document_note:before {
  content: "\ea66";
}
.icon-basic_elaboration_document_pencil:before {
  content: "\ea67";
}
.icon-basic_elaboration_document_picture:before {
  content: "\ea68";
}
.icon-basic_elaboration_document_plus:before {
  content: "\ea69";
}
.icon-basic_elaboration_document_previous:before {
  content: "\ea6a";
}
.icon-basic_elaboration_document_refresh:before {
  content: "\ea6b";
}
.icon-basic_elaboration_document_remove:before {
  content: "\ea6c";
}
.icon-basic_elaboration_document_search:before {
  content: "\ea6d";
}
.icon-basic_elaboration_document_star:before {
  content: "\ea6e";
}
.icon-basic_elaboration_document_upload:before {
  content: "\ea6f";
}
.icon-basic_elaboration_folder_check:before {
  content: "\ea70";
}
.icon-basic_elaboration_folder_cloud:before {
  content: "\ea71";
}
.icon-basic_elaboration_folder_document:before {
  content: "\ea72";
}
.icon-basic_elaboration_folder_download:before {
  content: "\ea73";
}
.icon-basic_elaboration_folder_flagged:before {
  content: "\ea74";
}
.icon-basic_elaboration_folder_graph:before {
  content: "\ea75";
}
.icon-basic_elaboration_folder_heart:before {
  content: "\ea76";
}
.icon-basic_elaboration_folder_minus:before {
  content: "\ea77";
}
.icon-basic_elaboration_folder_next:before {
  content: "\ea78";
}
.icon-basic_elaboration_folder_noaccess:before {
  content: "\ea79";
}
.icon-basic_elaboration_folder_note:before {
  content: "\ea7a";
}
.icon-basic_elaboration_folder_pencil:before {
  content: "\ea7b";
}
.icon-basic_elaboration_folder_picture:before {
  content: "\ea7c";
}
.icon-basic_elaboration_folder_plus:before {
  content: "\ea7d";
}
.icon-basic_elaboration_folder_previous:before {
  content: "\ea7e";
}
.icon-basic_elaboration_folder_refresh:before {
  content: "\ea7f";
}
.icon-basic_elaboration_folder_remove:before {
  content: "\ea80";
}
.icon-basic_elaboration_folder_search:before {
  content: "\ea81";
}
.icon-basic_elaboration_folder_star:before {
  content: "\ea82";
}
.icon-basic_elaboration_folder_upload:before {
  content: "\ea83";
}
.icon-basic_elaboration_mail_check:before {
  content: "\ea84";
}
.icon-basic_elaboration_mail_cloud:before {
  content: "\ea85";
}
.icon-basic_elaboration_mail_document:before {
  content: "\ea86";
}
.icon-basic_elaboration_mail_download:before {
  content: "\ea87";
}
.icon-basic_elaboration_mail_flagged:before {
  content: "\ea88";
}
.icon-basic_elaboration_mail_heart:before {
  content: "\ea89";
}
.icon-basic_elaboration_mail_next:before {
  content: "\ea8a";
}
.icon-basic_elaboration_mail_noaccess:before {
  content: "\ea8b";
}
.icon-basic_elaboration_mail_note:before {
  content: "\ea8c";
}
.icon-basic_elaboration_mail_pencil:before {
  content: "\ea8d";
}
.icon-basic_elaboration_mail_picture:before {
  content: "\ea8e";
}
.icon-basic_elaboration_mail_previous:before {
  content: "\ea8f";
}
.icon-basic_elaboration_mail_refresh:before {
  content: "\ea90";
}
.icon-basic_elaboration_mail_remove:before {
  content: "\ea91";
}
.icon-basic_elaboration_mail_search:before {
  content: "\ea92";
}
.icon-basic_elaboration_mail_star:before {
  content: "\ea93";
}
.icon-basic_elaboration_mail_upload:before {
  content: "\ea94";
}
.icon-basic_elaboration_message_check:before {
  content: "\ea95";
}
.icon-basic_elaboration_message_dots:before {
  content: "\ea96";
}
.icon-basic_elaboration_message_happy:before {
  content: "\ea97";
}
.icon-basic_elaboration_message_heart:before {
  content: "\ea98";
}
.icon-basic_elaboration_message_minus:before {
  content: "\ea99";
}
.icon-basic_elaboration_message_note:before {
  content: "\ea9a";
}
.icon-basic_elaboration_message_plus:before {
  content: "\ea9b";
}
.icon-basic_elaboration_message_refresh:before {
  content: "\ea9c";
}
.icon-basic_elaboration_message_remove:before {
  content: "\ea9d";
}
.icon-basic_elaboration_message_sad:before {
  content: "\ea9e";
}
.icon-basic_elaboration_smartphone_cloud:before {
  content: "\ea9f";
}
.icon-basic_elaboration_smartphone_heart:before {
  content: "\eaa0";
}
.icon-basic_elaboration_smartphone_noaccess:before {
  content: "\eaa1";
}
.icon-basic_elaboration_smartphone_note:before {
  content: "\eaa2";
}
.icon-basic_elaboration_smartphone_pencil:before {
  content: "\eaa3";
}
.icon-basic_elaboration_smartphone_picture:before {
  content: "\eaa4";
}
.icon-basic_elaboration_smartphone_refresh:before {
  content: "\eaa5";
}
.icon-basic_elaboration_smartphone_search:before {
  content: "\eaa6";
}
.icon-basic_elaboration_tablet_cloud:before {
  content: "\eaa7";
}
.icon-basic_elaboration_tablet_heart:before {
  content: "\eaa8";
}
.icon-basic_elaboration_tablet_noaccess:before {
  content: "\eaa9";
}
.icon-basic_elaboration_tablet_note:before {
  content: "\eaaa";
}
.icon-basic_elaboration_tablet_pencil:before {
  content: "\eaab";
}
.icon-basic_elaboration_tablet_picture:before {
  content: "\eaac";
}
.icon-basic_elaboration_tablet_refresh:before {
  content: "\eaad";
}
.icon-basic_elaboration_tablet_search:before {
  content: "\eaae";
}
.icon-basic_elaboration_todolist_2:before {
  content: "\eaaf";
}
.icon-basic_elaboration_todolist_check:before {
  content: "\eab0";
}
.icon-basic_elaboration_todolist_cloud:before {
  content: "\eab1";
}
.icon-basic_elaboration_todolist_download:before {
  content: "\eab2";
}
.icon-basic_elaboration_todolist_flagged:before {
  content: "\eab3";
}
.icon-basic_elaboration_todolist_minus:before {
  content: "\eab4";
}
.icon-basic_elaboration_todolist_noaccess:before {
  content: "\eab5";
}
.icon-basic_elaboration_todolist_pencil:before {
  content: "\eab6";
}
.icon-basic_elaboration_todolist_plus:before {
  content: "\eab7";
}
.icon-basic_elaboration_todolist_refresh:before {
  content: "\eab8";
}
.icon-basic_elaboration_todolist_remove:before {
  content: "\eab9";
}
.icon-basic_elaboration_todolist_search:before {
  content: "\eaba";
}
.icon-basic_elaboration_todolist_star:before {
  content: "\eabb";
}
.icon-basic_elaboration_todolist_upload:before {
  content: "\eabc";
}
.icon-basic_accelerator:before {
  content: "\eabd";
}
.icon-basic_alarm:before {
  content: "\eabe";
}
.icon-basic_anchor:before {
  content: "\eabf";
}
.icon-basic_anticlockwise:before {
  content: "\eac0";
}
.icon-basic_archive_full:before {
  content: "\eac1";
}
.icon-basic_archive:before {
  content: "\eac2";
}
.icon-basic_ban:before {
  content: "\eac3";
}
.icon-basic_battery_charge:before {
  content: "\eac4";
}
.icon-basic_battery_empty:before {
  content: "\eac5";
}
.icon-basic_battery_full:before {
  content: "\eac6";
}
.icon-basic_battery_half:before {
  content: "\eac7";
}
.icon-basic_bolt:before {
  content: "\eac8";
}
.icon-basic_book_pen:before {
  content: "\eac9";
}
.icon-basic_book_pencil:before {
  content: "\eaca";
}
.icon-basic_book:before {
  content: "\eacb";
}
.icon-basic_bookmark:before {
  content: "\eacc";
}
.icon-basic_calculator:before {
  content: "\eacd";
}
.icon-basic_calendar:before {
  content: "\eace";
}
.icon-basic_cards_diamonds:before {
  content: "\eacf";
}
.icon-basic_cards_hearts:before {
  content: "\ead0";
}
.icon-basic_case:before {
  content: "\ead1";
}
.icon-basic_chronometer:before {
  content: "\ead2";
}
.icon-basic_clessidre:before {
  content: "\ead3";
}
.icon-basic_clock:before {
  content: "\ead4";
}
.icon-basic_clockwise:before {
  content: "\ead5";
}
.icon-basic_cloud:before {
  content: "\ead6";
}
.icon-basic_clubs:before {
  content: "\ead7";
}
.icon-basic_compass:before {
  content: "\ead8";
}
.icon-basic_cup:before {
  content: "\ead9";
}
.icon-basic_diamonds:before {
  content: "\eada";
}
.icon-basic_display:before {
  content: "\eadb";
}
.icon-basic_download:before {
  content: "\eadc";
}
.icon-basic_exclamation:before {
  content: "\eadd";
}
.icon-basic_eye_closed:before {
  content: "\eade";
}
.icon-basic_eye:before {
  content: "\eadf";
}
.icon-basic_female:before {
  content: "\eae0";
}
.icon-basic_flag1:before {
  content: "\eae1";
}
.icon-basic_flag2:before {
  content: "\eae2";
}
.icon-basic_floppydisk:before {
  content: "\eae3";
}
.icon-basic_folder_multiple:before {
  content: "\eae4";
}
.icon-basic_folder:before {
  content: "\eae5";
}
.icon-basic_gear:before {
  content: "\eae6";
}
.icon-basic_geolocalize-01:before {
  content: "\eae7";
}
.icon-basic_geolocalize-05:before {
  content: "\eae8";
}
.icon-basic_globe:before {
  content: "\eae9";
}
.icon-basic_gunsight:before {
  content: "\eaea";
}
.icon-basic_hammer:before {
  content: "\eaeb";
}
.icon-basic_headset:before {
  content: "\eaec";
}
.icon-basic_heart_broken:before {
  content: "\eaed";
}
.icon-basic_heart:before {
  content: "\eaee";
}
.icon-basic_helm:before {
  content: "\eaef";
}
.icon-basic_home:before {
  content: "\eaf0";
}
.icon-basic_info:before {
  content: "\eaf1";
}
.icon-basic_ipod:before {
  content: "\eaf2";
}
.icon-basic_joypad:before {
  content: "\eaf3";
}
.icon-basic_key:before {
  content: "\eaf4";
}
.icon-basic_keyboard:before {
  content: "\eaf5";
}
.icon-basic_laptop:before {
  content: "\eaf6";
}
.icon-basic_life_buoy:before {
  content: "\eaf7";
}
.icon-basic_lightbulb:before {
  content: "\eaf8";
}
.icon-basic_link:before {
  content: "\eaf9";
}
.icon-basic_lock_open:before {
  content: "\eafa";
}
.icon-basic_lock:before {
  content: "\eafb";
}
.icon-basic_magic_mouse:before {
  content: "\eafc";
}
.icon-basic_magnifier_minus:before {
  content: "\eafd";
}
.icon-basic_magnifier_plus:before {
  content: "\eafe";
}
.icon-basic_magnifier:before {
  content: "\eaff";
}
.icon-basic_mail_multiple:before {
  content: "\eb00";
}
.icon-basic_mail_open_text:before {
  content: "\eb01";
}
.icon-basic_mail_open:before {
  content: "\eb02";
}
.icon-basic_mail:before {
  content: "\eb03";
}
.icon-basic_male:before {
  content: "\eb04";
}
.icon-basic_map:before {
  content: "\eb05";
}
.icon-basic_message_multiple:before {
  content: "\eb06";
}
.icon-basic_message_txt:before {
  content: "\eb07";
}
.icon-basic_message:before {
  content: "\eb08";
}
.icon-basic_mixer2:before {
  content: "\eb09";
}
.icon-basic_mouse:before {
  content: "\eb0a";
}
.icon-basic_notebook_pen:before {
  content: "\eb0b";
}
.icon-basic_notebook_pencil:before {
  content: "\eb0c";
}
.icon-basic_notebook:before {
  content: "\eb0d";
}
.icon-basic_paperplane:before {
  content: "\eb0e";
}
.icon-basic_pencil_ruler_pen:before {
  content: "\eb0f";
}
.icon-basic_pencil_ruler:before {
  content: "\eb10";
}
.icon-basic_photo:before {
  content: "\eb11";
}
.icon-basic_picture_multiple:before {
  content: "\eb12";
}
.icon-basic_picture:before {
  content: "\eb13";
}
.icon-basic_pin1:before {
  content: "\eb14";
}
.icon-basic_pin2:before {
  content: "\eb15";
}
.icon-basic_postcard_multiple:before {
  content: "\eb16";
}
.icon-basic_postcard:before {
  content: "\eb17";
}
.icon-basic_printer:before {
  content: "\eb18";
}
.icon-basic_question:before {
  content: "\eb19";
}
.icon-basic_rss:before {
  content: "\eb1a";
}
.icon-basic_server_cloud:before {
  content: "\eb1b";
}
.icon-basic_server_download:before {
  content: "\eb1c";
}
.icon-basic_server_upload:before {
  content: "\eb1d";
}
.icon-basic_server:before {
  content: "\eb1e";
}
.icon-basic_server2:before {
  content: "\eb1f";
}
.icon-basic_settings:before {
  content: "\eb20";
}
.icon-basic_share:before {
  content: "\eb21";
}
.icon-basic_sheet_multiple:before {
  content: "\eb22";
}
.icon-basic_sheet_pen:before {
  content: "\eb23";
}
.icon-basic_sheet_pencil:before {
  content: "\eb24";
}
.icon-basic_sheet_txt:before {
  content: "\eb25";
}
.icon-basic_sheet:before {
  content: "\eb26";
}
.icon-basic_signs:before {
  content: "\eb27";
}
.icon-basic_smartphone:before {
  content: "\eb28";
}
.icon-basic_spades:before {
  content: "\eb29";
}
.icon-basic_spread_bookmark:before {
  content: "\eb2a";
}
.icon-basic_spread_text_bookmark:before {
  content: "\eb2b";
}
.icon-basic_spread_text:before {
  content: "\eb2c";
}
.icon-basic_spread:before {
  content: "\eb2d";
}
.icon-basic_star:before {
  content: "\eb2e";
}
.icon-basic_tablet:before {
  content: "\eb2f";
}
.icon-basic_target:before {
  content: "\eb30";
}
.icon-basic_todo_pen:before {
  content: "\eb31";
}
.icon-basic_todo_pencil:before {
  content: "\eb32";
}
.icon-basic_todo_txt:before {
  content: "\eb33";
}
.icon-basic_todo:before {
  content: "\eb34";
}
.icon-basic_todolist_pen:before {
  content: "\eb35";
}
.icon-basic_todolist_pencil:before {
  content: "\eb36";
}
.icon-basic_trashcan_full:before {
  content: "\eb37";
}
.icon-basic_trashcan_refresh:before {
  content: "\eb38";
}
.icon-basic_trashcan_remove:before {
  content: "\eb39";
}
.icon-basic_trashcan:before {
  content: "\eb3a";
}
.icon-basic_upload:before {
  content: "\eb3b";
}
.icon-basic_usb:before {
  content: "\eb3c";
}
.icon-basic_video:before {
  content: "\eb3d";
}
.icon-basic_watch:before {
  content: "\eb3e";
}
.icon-basic_webpage_img_txt:before {
  content: "\eb3f";
}
.icon-basic_webpage_multiple:before {
  content: "\eb40";
}
.icon-basic_webpage_txt:before {
  content: "\eb41";
}
.icon-basic_webpage:before {
  content: "\eb42";
}
.icon-basic_world:before {
  content: "\eb43";
}
.icon-arrows_anticlockwise_dashed:before {
  content: "\eb44";
}
.icon-arrows_anticlockwise:before {
  content: "\eb45";
}
.icon-arrows_button_down:before {
  content: "\eb46";
}
.icon-arrows_button_off:before {
  content: "\eb47";
}
.icon-arrows_button_on:before {
  content: "\eb48";
}
.icon-arrows_button_up:before {
  content: "\eb49";
}
.icon-arrows_check:before {
  content: "\eb4a";
}
.icon-arrows_circle_check:before {
  content: "\eb4b";
}
.icon-arrows_circle_down:before {
  content: "\eb4c";
}
.icon-arrows_circle_downleft:before {
  content: "\eb4d";
}
.icon-arrows_circle_downright:before {
  content: "\eb4e";
}
.icon-arrows_circle_left:before {
  content: "\eb4f";
}
.icon-arrows_circle_minus:before {
  content: "\eb50";
}
.icon-arrows_circle_plus:before {
  content: "\eb51";
}
.icon-arrows_circle_remove:before {
  content: "\eb52";
}
.icon-arrows_circle_right:before {
  content: "\eb53";
}
.icon-arrows_circle_up:before {
  content: "\eb54";
}
.icon-arrows_circle_upleft:before {
  content: "\eb55";
}
.icon-arrows_circle_upright:before {
  content: "\eb56";
}
.icon-arrows_clockwise_dashed:before {
  content: "\eb57";
}
.icon-arrows_clockwise:before {
  content: "\eb58";
}
.icon-arrows_compress:before {
  content: "\eb59";
}
.icon-arrows_deny:before {
  content: "\eb5a";
}
.icon-arrows_diagonal:before {
  content: "\eb5b";
}
.icon-arrows_diagonal2:before {
  content: "\eb5c";
}
.icon-arrows_down_double-34:before {
  content: "\eb5d";
}
.icon-arrows_down:before {
  content: "\eb5e";
}
.icon-arrows_downleft:before {
  content: "\eb5f";
}
.icon-arrows_downright:before {
  content: "\eb60";
}
.icon-arrows_drag_down_dashed:before {
  content: "\eb61";
}
.icon-arrows_drag_down:before {
  content: "\eb62";
}
.icon-arrows_drag_horiz:before {
  content: "\eb63";
}
.icon-arrows_drag_left_dashed:before {
  content: "\eb64";
}
.icon-arrows_drag_left:before {
  content: "\eb65";
}
.icon-arrows_drag_right_dashed:before {
  content: "\eb66";
}
.icon-arrows_drag_right:before {
  content: "\eb67";
}
.icon-arrows_drag_up_dashed:before {
  content: "\eb68";
}
.icon-arrows_drag_up:before {
  content: "\eb69";
}
.icon-arrows_drag_vert:before {
  content: "\eb6a";
}
.icon-arrows_exclamation:before {
  content: "\eb6b";
}
.icon-arrows_expand_diagonal1:before {
  content: "\eb6c";
}
.icon-arrows_expand_horizontal1:before {
  content: "\eb6d";
}
.icon-arrows_expand_vertical1:before {
  content: "\eb6e";
}
.icon-arrows_expand:before {
  content: "\eb6f";
}
.icon-arrows_fit_horizontal:before {
  content: "\eb70";
}
.icon-arrows_fit_vertical:before {
  content: "\eb71";
}
.icon-arrows_glide_horizontal:before {
  content: "\eb72";
}
.icon-arrows_glide_vertical:before {
  content: "\eb73";
}
.icon-arrows_glide:before {
  content: "\eb74";
}
.icon-arrows_hamburger-2:before {
  content: "\eb75";
}
.icon-arrows_hamburger1:before {
  content: "\eb76";
}
.icon-arrows_horizontal:before {
  content: "\eb77";
}
.icon-arrows_info:before {
  content: "\eb78";
}
.icon-arrows_keyboard_alt:before {
  content: "\eb79";
}
.icon-arrows_keyboard_cmd-29:before {
  content: "\eb7a";
}
.icon-arrows_keyboard_delete:before {
  content: "\eb7b";
}
.icon-arrows_keyboard_down-28:before {
  content: "\eb7c";
}
.icon-arrows_keyboard_left:before {
  content: "\eb7d";
}
.icon-arrows_keyboard_return:before {
  content: "\eb7e";
}
.icon-arrows_keyboard_right:before {
  content: "\eb7f";
}
.icon-arrows_keyboard_shift:before {
  content: "\eb80";
}
.icon-arrows_keyboard_tab:before {
  content: "\eb81";
}
.icon-arrows_keyboard_up:before {
  content: "\eb82";
}
.icon-arrows_left_double-32:before {
  content: "\eb83";
}
.icon-arrows_left:before {
  content: "\eb84";
}
.icon-arrows_minus:before {
  content: "\eb85";
}
.icon-arrows_move_bottom:before {
  content: "\eb86";
}
.icon-arrows_move_left:before {
  content: "\eb87";
}
.icon-arrows_move_right:before {
  content: "\eb88";
}
.icon-arrows_move_top:before {
  content: "\eb89";
}
.icon-arrows_move:before {
  content: "\eb8a";
}
.icon-arrows_move2:before {
  content: "\eb8b";
}
.icon-arrows_plus:before {
  content: "\eb8c";
}
.icon-arrows_question:before {
  content: "\eb8d";
}
.icon-arrows_remove:before {
  content: "\eb8e";
}
.icon-arrows_right_double-31:before {
  content: "\eb8f";
}
.icon-arrows_right:before {
  content: "\eb90";
}
.icon-arrows_rotate_anti_dashed:before {
  content: "\eb91";
}
.icon-arrows_rotate_anti:before {
  content: "\eb92";
}
.icon-arrows_rotate_dashed:before {
  content: "\eb93";
}
.icon-arrows_rotate:before {
  content: "\eb94";
}
.icon-arrows_shrink_diagonal1:before {
  content: "\eb95";
}
.icon-arrows_shrink_diagonal2:before {
  content: "\eb96";
}
.icon-arrows_shrink_horizonal2:before {
  content: "\eb97";
}
.icon-arrows_shrink_horizontal1:before {
  content: "\eb98";
}
.icon-arrows_shrink_vertical1:before {
  content: "\eb99";
}
.icon-arrows_shrink_vertical2:before {
  content: "\eb9a";
}
.icon-arrows_shrink:before {
  content: "\eb9b";
}
.icon-arrows_sign_down:before {
  content: "\eb9c";
}
.icon-arrows_sign_left:before {
  content: "\eb9d";
}
.icon-arrows_sign_right:before {
  content: "\eb9e";
}
.icon-arrows_sign_up:before {
  content: "\eb9f";
}
.icon-arrows_slide_down1:before {
  content: "\eba0";
}
.icon-arrows_slide_down2:before {
  content: "\eba1";
}
.icon-arrows_slide_left1:before {
  content: "\eba2";
}
.icon-arrows_slide_left2:before {
  content: "\eba3";
}
.icon-arrows_slide_right1:before {
  content: "\eba4";
}
.icon-arrows_slide_right2:before {
  content: "\eba5";
}
.icon-arrows_slide_up1:before {
  content: "\eba6";
}
.icon-arrows_slide_up2:before {
  content: "\eba7";
}
.icon-arrows_slim_down_dashed:before {
  content: "\eba8";
}
.icon-arrows_slim_down:before {
  content: "\eba9";
}
.icon-arrows_slim_left_dashed:before {
  content: "\ebaa";
}
.icon-arrows_slim_left:before {
  content: "\ebab";
}
.icon-arrows_slim_right_dashed:before {
  content: "\ebac";
}
.icon-arrows_slim_right:before {
  content: "\ebad";
}
.icon-arrows_slim_up_dashed:before {
  content: "\ebae";
}
.icon-arrows_slim_up:before {
  content: "\ebaf";
}
.icon-arrows_square_check:before {
  content: "\ebb0";
}
.icon-arrows_square_down:before {
  content: "\ebb1";
}
.icon-arrows_square_downleft:before {
  content: "\ebb2";
}
.icon-arrows_square_downright:before {
  content: "\ebb3";
}
.icon-arrows_square_left:before {
  content: "\ebb4";
}
.icon-arrows_square_minus:before {
  content: "\ebb5";
}
.icon-arrows_square_plus:before {
  content: "\ebb6";
}
.icon-arrows_square_remove:before {
  content: "\ebb7";
}
.icon-arrows_square_right:before {
  content: "\ebb8";
}
.icon-arrows_square_up:before {
  content: "\ebb9";
}
.icon-arrows_square_upleft:before {
  content: "\ebba";
}
.icon-arrows_square_upright:before {
  content: "\ebbb";
}
.icon-arrows_squares:before {
  content: "\ebbc";
}
.icon-arrows_stretch_diagonal1:before {
  content: "\ebbd";
}
.icon-arrows_stretch_diagonal2:before {
  content: "\ebbe";
}
.icon-arrows_stretch_diagonal3:before {
  content: "\ebbf";
}
.icon-arrows_stretch_diagonal4:before {
  content: "\ebc0";
}
.icon-arrows_stretch_horizontal1:before {
  content: "\ebc1";
}
.icon-arrows_stretch_horizontal2:before {
  content: "\ebc2";
}
.icon-arrows_stretch_vertical1:before {
  content: "\ebc3";
}
.icon-arrows_stretch_vertical2:before {
  content: "\ebc4";
}
.icon-arrows_switch_horizontal:before {
  content: "\ebc5";
}
.icon-arrows_switch_vertical:before {
  content: "\ebc6";
}
.icon-arrows_up_double-33:before {
  content: "\ebc7";
}
.icon-arrows_up:before {
  content: "\ebc8";
}
.icon-arrows_upleft:before {
  content: "\ebc9";
}
.icon-arrows_upright:before {
  content: "\ebca";
}
.icon-arrows_vertical:before {
  content: "\ebcb";
}
