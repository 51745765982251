//
// colors
//
//

// background colors
@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value);
}

.bg-gradient {
    //background-image: linear-gradient(to top left,#3ca649,#79bd49 59%,#92c749);
    //background-image: linear-gradient(to top left,#2a8a42,#5b9944 59%,#77a444);
    background-image: linear-gradient(to top left,#309949,#64aa4b 59%,#83b54c);
}

.bg-image {
	background-size: cover;
	background-position: center;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba($black, .4);
		z-index: 10;
	}

	> .container {
		position: relative;
		z-index: 20;
	}

	> .image {
		&[class*="blured"] {
			@include prefix(filter, blur(5px), webkit moz o ms);
			position: absolute;
			top: -2.5rem;
			right: -2.5rem;
			bottom: -2.5rem;
			left: -2.5rem;
		}
	}


}

// text colors
@each $color, $value in $colors {
	@include text-emphasis-variant(".text-#{$color}", $value);
}

@each $color, $value in $socials {
	@include text-emphasis-variant(".text-#{$color}", $value);
}

.text-black { color: $black!important; }
