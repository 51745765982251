// Custom Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Typogrpahy
$font-icon:                'icomoon';

$letter-spacing:           0.05em;
$letter-spacing-sm:        ($letter-spacing / 2);
$letter-spacing-lg:        ($letter-spacing * 2);


// Layout
$section-padding:          6.25rem;



//
// Color system
//

// text-whiteed Colors
$text-white-primary:         $white;
$text-white-secondary:       rgba($white, 0.8);
$text-white-border:          rgba($white, 0.2);

// text-blackened colors
$text-dark-primary:        $gray-600;
$text-dark-secondary:      rgba($gray-600, 0.8);
$text-dark-border:         rgba($gray-600, 0.2);

// Social Media Colors
$facebook:                 #3b5998;
$google-plus:              #dd4b39;
$twitter:                  #00aced;
$linkedin:                 #007bb6;
$pinterest:                #cb2027;
$git:                      #666666;
$tumblr:                   #32506d;
$vimeo:                    #aad450;
$youtube:                  #bb0000;
$flickr:                   #ff0084;
$reddit:                   #ff4500;
$dribbble:                 #ea4c89;
$skype:                    #00aff0;
$instagram:                #517fa4;
$behance:                  #1769ff;
$rss:                      #f26522;

$socials: ();
$socials: map-merge((
  "facebook": $facebook,
  "google-plus": $google-plus,
  "twitter": $twitter,
  "linkedin": $linkedin,
  "pinterest": $pinterest,
  "git": $git,
  "tumblr": $tumblr,
  "vimeo": $vimeo,
  "flickr": $flickr,
  "reddit": $reddit,
  "dribbble": $dribbble,
  "skype": $skype,
  "instagram": $instagram,
  "behance": $behance,
  "rss": $rss
), $socials);

%nav-link-font {
    font-size: $font-size-lg;
    //font-weight: 600;
    //letter-spacing: $letter-spacing-sm;
    //text-transform: uppercase;
}
