@charset 'UTF-8';

// Fonts
//@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');
//@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700');

/* rubik-300 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/rubik-v8-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Rubik Light'), local('Rubik-Light'),
       url('../fonts/rubik-v8-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rubik-v8-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rubik-v8-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rubik-v8-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rubik-v8-latin-300.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/rubik-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Rubik'), local('Rubik-Regular'),
       url('../fonts/rubik-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rubik-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rubik-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rubik-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rubik-v8-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/rubik-v8-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Rubik Medium'), local('Rubik-Medium'),
       url('../fonts/rubik-v8-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rubik-v8-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rubik-v8-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rubik-v8-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rubik-v8-latin-500.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/rubik-v8-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Rubik Bold'), local('Rubik-Bold'),
       url('../fonts/rubik-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/rubik-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/rubik-v8-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/rubik-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/rubik-v8-latin-700.svg#Rubik') format('svg'); /* Legacy iOS */
}
/* nunito-sans-300 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-sans-v4-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Light'), local('NunitoSans-Light'),
       url('../fonts/nunito-sans-v4-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v4-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v4-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v4-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v4-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-sans-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
       url('../fonts/nunito-sans-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v4-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-sans-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
       url('../fonts/nunito-sans-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v4-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v4-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
/* cabin-regular - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  src: local('Cabin'), local('Cabin-Regular'),
       url('../fonts/cabin-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/cabin-v14-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cabin-600 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  src: local('Cabin SemiBold'), local('Cabin-SemiBold'),
       url('../fonts/cabin-v14-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/cabin-v14-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-700 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  src: local('Cabin Bold'), local('Cabin-Bold'),
       url('../fonts/cabin-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/cabin-v14-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* special font for at symbol */
@font-face {
    font-family: 'Replace At';
    src: local('Rubik'),
         url('../fonts/rubik-v8-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/rubik-v8-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+40;
}


// Bootstrap & Variables
@import "../../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "variables-custom";
@import "../../../node_modules/bootstrap/scss/bootstrap";


// Components
@import "layout/_layout";
@import "components/_components";
