//
// Buttons
//


// .btn {
//   letter-spacing: $letter-spacing;
// }
.btn-dy {
    font-size: $font-size-lg !important;
    font-weight: 600;
}

@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color, $value in $socials {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $socials {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color, $value in $grays {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $grays {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


// Rounded
.btn-rounded { border-radius: 10rem; }


//
// with icon
//
.btn-with-ico {
  position: relative;
  padding-left: ($input-btn-padding-y * 2 + $input-btn-padding-x + $line-height-base);

  [class*="icon"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    line-height: ($input-btn-padding-y * 2 + $line-height-base);
    font-size: 24px;
    width: ($input-btn-padding-y * 2 + $line-height-base);
    background: rgba($black, .1);
  }

  &.btn-lg {
    padding-right: ($input-btn-padding-y-lg * 2 + $input-btn-padding-x-lg + $line-height-base);

    [class*="icon"] {
      line-height: ($input-btn-padding-y-lg * 2 + $line-height-base);
      width: ($input-btn-padding-y-lg * 2 + $line-height-base);
    }
  }

  &.btn-sm {
    padding-right: ($input-btn-padding-y-sm * 2 + $input-btn-padding-x-sm + $line-height-base);

    [class*="icon"] {
      line-height: ($input-btn-padding-y-sm * 2 + $line-height-base);
      width: ($input-btn-padding-y-sm * 2 + $line-height-base);
    }
  }

  &.btn-rounded {
    [class*="icon"] {
      border-radius: 50%;
    }
  }


  // with icon + outlined
  &[class*="-outline-"] {
    padding-left: ($input-btn-padding-y / 2 + $input-btn-padding-x + $line-height-base);

    [class*="icon"] {
      background: transparent;
    }

    &.btn-lg {
      padding-right: ($input-btn-padding-y-lg / 2 + $input-btn-padding-x-lg + $line-height-base);
    }

    &.btn-sm {
      padding-right: ($input-btn-padding-y-sm / 2 + $input-btn-padding-x-sm + $line-height-base);
    }
  }
}


//
// icon
//
.btn-ico {
  position: relative;
	width: ($input-btn-padding-y * 2 + $line-height-base);
  height: ($input-btn-padding-y * 2 + $line-height-base);
  padding: 0;

  [class*="icon"] {
    @include translate(-50%,-50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &.btn-sm {
    width: ($input-btn-padding-y-sm * 2 + $line-height-base);
    height: ($input-btn-padding-y-sm * 2 + $line-height-base);
  }

  &.btn-lg {
    width: ($input-btn-padding-y-lg * 2 + $line-height-base);
    height: ($input-btn-padding-y-lg * 2 + $line-height-base);
  }

  &.btn-xl {
    width: ($input-btn-padding-y-lg * 4 + $line-height-base);
    height: ($input-btn-padding-y-lg * 4 + $line-height-base);
  }
}

.btn-with-arrow {
    &::after {
        margin-left: 0.5em;
        content: "\1F862";
    }
}

button:focus { outline: none; }
