// 
// navbar
//
//

.navbar-container {
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@include media-breakpoint-up(lg) {
    .navbar-container {
        max-width: map-get($container-max-widths, "lg");
    }
}
@include media-breakpoint-up(xl) {
    .navbar-container {
        max-width: map-get($container-max-widths, "xl");
    }
}
@include media-breakpoint-up(lg) {
    .navbar-container {
        padding-right: 15px;
    }
}

.navbar {
	padding-left: 0;
	padding-right: 0;
}

.navbar-brand {
	height: 46px;
	margin-top: .5rem;
	margin-bottom: .5rem;
	margin-right: 2rem;

	&.navbar-brand-sm { height: 36px; }
	&.navbar-brand-lg { height: 72px; }

	.navbar-logo {
		width: auto;
		max-height: 100%;
		display: none;
	}
}

.navbar-nav {
	position: relative;
	
    .nav-item {
		+ .nav-item {
			margin-left: 1.5rem;
		}
	}

	.nav-link {
        @extend %nav-link-font;
		@include transition;
		position: relative;
		display: inline-block;
		color: $gray-600;
		z-index: 5;

        &:active {
            color: $primary;
        }
        
        &:hover {
            color: $primary;
        }

	}

	.lavalamp-object {
		display: none;
		z-index: 1;
	}
}

/*
.navbar-nav {
	.nav-item {
		+ .nav-item {
			margin-left: 1rem;
		}
	}

	.nav-link {
		@include transition;
		font-size: $font-size-sm;
	}
}
*/

.navbar-toggler {
	padding: 0!important;
	border: 0;
}


// Responsive
//
// Mobile View for Navbar
@include media-breakpoint-down(md) {

	.navbar-logo-dark {
		display: block!important;
	}

	.navbar {
		.navbar-toggler {
			padding: .5rem;
			color: $navbar-light-color;
			border-color: $navbar-light-toggler-border-color;
			border-radius: 50%;
		}

		.nav-item {
			+ .nav-item {
				margin: 0;
			}

			.nav-link {
				padding: 5px 0;
				color: $gray-600;

				//&:focus,
				&:hover {
					color: $primary;
				}
			}

			&.show,
			&.active {
				.nav-link {
					color: $primary;
				}
			}
		}
	}


	.navbar {
		.banner {
			display: none;
		}
	}

	.navbar-nav {

		.nav-item {
			width: 100%;
			padding: .5rem 0;

			.btn {
				display: block;
				background-color: $primary;
				color: $white;
			}
		}
	}
}


.overlay-active {
	.headroom {
		max-height: 100vh;
		overflow: hidden;
		overflow-y: scroll;
	}
}
